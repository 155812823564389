import React, { useEffect, useState } from 'react';
import { TextField, Button, 
		Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, InputAdornment, IconButton, Tooltip} from '@material-ui/core';
import QrReader from 'react-qr-reader';
import  { useAuth } from './common/Auth'; 

import {firestore, FieldValue} from './common/firebase';
import { useCurrentSystem } from './context/SystemContext';
import { CropFree } from '@material-ui/icons';

function GatewayEditor({gateway, sacn, open, editGateway,  title, newgateway , endEdit}){

	const [values, setValues] = React.useState({name: '', location: '', device_id: '' });
	const [sacnSettings, setSacnSettings ] = React.useState( { destination:'', priority: 100, universe: 0 } );
	const [ scanning, setScanning ] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState(undefined);
	const currentUser = useAuth();
	const { currentSystem } = useCurrentSystem();

	
	useEffect( () => {
		var new_values = { ...values, ...gateway };
		setValues(new_values);

		if(gateway.device_id){
			fetchSACN(gateway.device_id);
		}
	}, [gateway] );

	const fetchSACN = async (id) => {
		var device_id = id;
		if(device_id && device_id.length){
			firestore.doc('gateways/' + device_id + '/config/sacn')
				.get()
				.then( (doc) =>{
					if(doc.exists){
						setSacnSettings(doc.data());
					} else {
						console.log("SACN config for " + device_id + " doesn't exist" );
					}
				})
				.catch( (err)=>{
					console.log("no sacn config for " + device_id );
					console.log(err);
				})
		}
	}

	const saveGateway = async() => {
		const device_id = values.device_id;
		
		let device_ref = firestore.collection('gateways').doc(device_id);
		if( newgateway ){
			let device_doc = await device_ref.get();
			if(!device_doc.exists){
				device_ref.set({
					...values,
					system_id : currentSystem.uid,
					createdAt : FieldValue.serverTimestamp(),
				});
				if(sacnSettings){
					var path = 'gateways/' + device_id + "/config/sacn"
					firestore.doc(path).set(sacnSettings);
				}
				return true;
			} else {
				setErrorMessage("Gateway is not available.  Please check the serial number and try again")
				return false;
			}
		} else {
			
			var upd = { ...values, system_id: currentSystem.uid  };
			device_ref.update(upd);
			if(sacnSettings){
				var path = 'gateways/' + device_id + "/config/sacn"
				firestore.doc(path).set(sacnSettings);
			}
			return true;
		}
	}

	const handleChange = name => event => {
		if(errorMessage){ setErrorMessage(undefined);}
		setValues({ ...values, [name]: event.target.value });
	};
	
	const handleSACN = name => event => {
		
		var settings = {...sacnSettings,[name]: event.target.value };
		setSacnSettings(settings);
		
	};
	
	const handleClose = () =>{
	   endEdit();
	}

	const apply = async() => {
		if( await saveGateway()){
			endEdit();
		}
	};

	const handleQRScan = (data )=> {
		var vals = JSON.parse(data);
		setValues( {...values, ...vals });
	}
	const handleError = (err )=> {
		console.log("Scan Error", err);
	}
	const openScanner = (evt) =>{
		setScanning(!scanning);
	}



	return(
		<div>
			<Dialog open={open} onClose={handleClose}>
			<DialogTitle>{title ? (title): ("Edit Gateway")}</DialogTitle>
			<DialogContent>
				{
					errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>
				}
				<form>
				<TextField fullWidth
					id="standard-name"
					label="Name"
					value={values.name}
					onChange={handleChange('name')}
					margin="normal"
					variant="outlined"
					
				/>
				<TextField fullWidth
					id="standard-location"
					label="Location"
					value={values.location}
					onChange={handleChange('location')}
					margin="normal"
					variant="outlined"
				/>
				<TextField fullWidth required 
					disabled={!newgateway}
					id="standard-serial"
					label="Serial Number"
					value={values.device_id}
					onChange={handleChange('device_id')}
					margin="normal"
					variant="outlined"
					
					InputProps={{
						endAdornment: <InputAdornment position="end"><Tooltip title="Scan QR Code"><IconButton disabled={!newgateway} onClick={openScanner}><CropFree /></IconButton></Tooltip></InputAdornment>,
					  }}
				/>
				
				{ scanning && 
					<>
					<QrReader delay={300} onError={handleError} onScan={handleQRScan} ></QrReader>
					<Button onClick={openScanner}>{scanning ?  "Close Scanner": "Scan QR Code" }</Button>
					</>
				 }

				 { !newgateway && values &&
					<>
						<TextField fullWidth
							id="standard-universe"
							label="sACN Universe"
							value={ sacnSettings.universe}
							onChange={handleSACN('universe')}
							margin="normal"
							variant="outlined"
						/>
						<TextField fullWidth
							id="standard-priority"
							label="sACN Priority"
							value={ sacnSettings.priority }
							onChange={handleSACN('priority')}
							margin="normal"
							variant="outlined"
						/>
						<TextField fullWidth
							id="standard-partner"
							label="Partner Device IP Address"
							value={ sacnSettings.destination }
							onChange={handleSACN('destination')}
							margin="normal"
							variant="outlined"
							/>
					</>
				 }
				 </form>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} >
					Cancel
				</Button>
				<Button onClick={apply}>Save</Button>
			</DialogActions>
			</Dialog>
		</div>
	);

}


export default GatewayEditor;