import React, { useState, useEffect } from "react";
import {useAuth } from './common/Auth';
import SystemEditor from "./SystemEditor";
import SystemView from "./SystemView";
import SystemList from "./SystemList";
import TopNav from "./TopNav";

import { useCurrentSystem } from "./context/SystemContext";
import { firestore } from "./common/firebase";

const Home = ({view}) => {
	const currentUser  = useAuth();
	const { currentSystem } = useCurrentSystem();

	const [editSystem, setEditSystem] = useState(false);
	const [sysToEdit, setSysToEdit] = useState({});
	const [creatingNewSystem, setCreatingNewSystem] = useState(false);

	const createNewSystem = () => {
		setCreatingNewSystem(true);
	};

	const doDeleteSystem = (system) => {
		var uid = system.uid;
		firestore
			.collection("systems")
			.doc(uid)
			.delete();
	};

	const doEditSystem = (system) => {
		setSysToEdit(system);
		setEditSystem(true);
	};

	const endEditSystem = () => {
		setEditSystem(false);
		setCreatingNewSystem(false);
	};

	const doForgetSystem = (system) => {
		if( system.guests != undefined && system.guests.length > 0){
			var index = system.guests.indexOf(currentUser.uid);
			if(index < system.guests.length){
				system.guests.splice(index, 1);
			}
		}
		delete system.permissions[currentUser.uid];
		firestore.collection("systems").doc(system.uid).update( { guests: system.guests, permissions: system.permissions })
	
 	}
	return (
		<>
			{currentSystem ? (
				<>
					<SystemView view={view}></SystemView>
				</>
			) : (
				<>
					<SystemList
						user={currentUser}
						deleteSystem={doDeleteSystem}
						newSystem={createNewSystem}
						editSystem={doEditSystem}
						forgetSystem={doForgetSystem}
					></SystemList>
					{((sysToEdit && editSystem) || creatingNewSystem) && (
						<SystemEditor
							system={
								creatingNewSystem
									? { name: "New System", location: "" }
									: sysToEdit
							}
							open={editSystem || creatingNewSystem}
							setOpen={endEditSystem}
							title={creatingNewSystem ? "New System" : "Edit System"}
							newsystem={creatingNewSystem}
						></SystemEditor>
					)}
				</>
			)}
		</>
	);
};

export default Home;
