import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	Route,
	Switch,
	useRouteMatch,
	useParams,
	Link,
	NavLink
} from "react-router-dom";
import { useAuth, useUserExt, useAgentExt } from "./common/Auth";
import {
	useAgentUsers,
	AgentCustomerProvider,
	useAgentCustomer
} from "./context/AgentContext";
import { Button, Drawer, List, ListItem, TableContainer, TableHead, Table, TableRow, TableCell, Paper} from "@material-ui/core";
import SystemList from './SystemList';
import LoadingIndicator from "./common/LoadingIndicator";
import { AirlineSeatFlat } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex"
	},
	appBar: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth
	},
	left: {
		backgroundColor: "#ccc",
		minHeight: "100vh",
		height: "100%",
		position: "absolute",
		width: `${drawerWidth}px`,
		width: drawerWidth,
		flexShrink: 0,
		flexGrow: 1,
	},
	main: {
		position: "absolute",
		minHeight: "100vh",

		width: `calc(100% - ${drawerWidth}px)`,
		left: `${drawerWidth}px`,
		backgroundColor: "#ddd",
	},
	drawerPaper: {
		width: drawerWidth
	},
	toolbar: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.default,
		padding: theme.spacing(3)
	}
}));

const drawerWidth = 240;

const AgentUserDetails = ({user}) => {

	const k = user ? Object.keys(user) : [];
	const userProps = k.map( (key) => 
		<TableRow key={key}>
			<TableCell>{ key.toString() }</TableCell>
			<TableCell>{user[key]}</TableCell>
		</TableRow>
	);
	
	return (
		<>
		<h3>Customer Details</h3>
		<TableContainer component={Paper} >
			<Table small>
				<TableHead>
					<TableCell>Property</TableCell>
					<TableCell>Value</TableCell>
				</TableHead>
				{userProps}
			</Table>
		</TableContainer>
		</>
	);
}

const AgentUser = () => {
	let { userID } = useParams();
	const {
		error,
		loading,
		agentCustomer,
		setAgentCustomerID
	} = useAgentCustomer();
	setAgentCustomerID(userID);

	return (
		<div>
			<LoadingIndicator loading={loading}></LoadingIndicator>
			<div>{ !loading && agentCustomer && 
				<>
					<AgentUserDetails user={agentCustomer} ></AgentUserDetails>
					<SystemList user={agentCustomer}></SystemList>
				</>
			}</div>
		</div>
	);
};

const AgentSystems = ({ agentID }) => {
	return <div>{"Agent Systems: ID " + agentID}</div>;
};

const Agent = () => {
	const classes = useStyles();
	let { path, url } = useRouteMatch();
	console.log(path, url);
	const currentUser = useAuth();
	const { userExt } = useUserExt(currentUser.uid);
	const { userAgent } = useAgentExt(currentUser.uid);
	const { users, loading: userLoading, error: userError } = useAgentUsers(
		userAgent ? userAgent.agentID : "0",
		100
	);

	return (
		<div className={classes.root}>
			<h3>Simulcast Agent</h3>

			<div className={classes.left}>
				<List>
					<h3>My Customers</h3>
					{userAgent &&
						(userAgent.revoked ? (
							<div>AGENT CREDENTIALS REVOKED.</div>
						) : (
							userAgent &&
							userAgent.agentID &&
							users &&
							users.map((user) => (
								<ListItem component={NavLink} to={`${url}/${user.uid}`}>
									<div key={user.uid}>{user.email}</div>
								</ListItem>
							))
						))}
				</List>
			</div>
			<div className={classes.main}>
			{userLoading && <div>Loading Customers</div>}

			{userError && <div>Error Loading Customers</div>}
			<AgentCustomerProvider>
				<Switch>
					<Route exact path={path}>
						<h3>Please select a customer.</h3>
					</Route>
					<Route path={`${path}/:userID`}>
						<AgentUser></AgentUser>
					</Route>
				</Switch>
			</AgentCustomerProvider>
			</div>
		</div>
	);
};
export default Agent;
