import React, { useEffect, useState } from 'react';
import { Typography, TextField, Button, Tabs, Tab, AppBar, Box,
    Card , Grid, CardContent, CardActions, 
    Dialog, DialogContent, DialogTitle,DialogContentText, DialogActions} from '@material-ui/core';
import  { useAuth } from './common/Auth'; 
import {firestore, FieldValue} from './common/firebase';

function a11yProps(index) {
	return {
	  id: `simple-tab-${index}`,
	  'aria-controls': `simple-tabpanel-${index}`,
	};
  }
function TabPanel(props) {
const { children, value, index, ...other } = props;

return (
	<div
	role="tabpanel"
	hidden={value !== index}
	id={`simple-tabpanel-${index}`}
	aria-labelledby={`simple-tab-${index}`}
	{...other}
	>
	{value === index && (
		<Box p={3}>
		<Typography>{children}</Typography>
		</Box>
	)}
	</div>
);
}
function SystemEditor({system, open, setOpen,  title, newsystem }){

	const [values, setValues] = React.useState({ name: "New System", location:"Calgary", num_colors: 1, code:"Enter a code"});
	const [tab, setTab ] = React.useState(0);
    const currentUser = useAuth();
    
    useEffect( () => {
		var vals = {...values, ...system};
		setValues(vals);
		
    }, [system] );


    const saveSystem = async() => {
        const { uid, code, ...rest } = values;
        if( newsystem ){
            firestore.collection('systems').add({
                ...rest,
                userId : currentUser.uid,
                createdAt : FieldValue.serverTimestamp(),
              });
        } else {
            firestore.collection('systems').doc(uid).update(rest);
        }
    }

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };
    
    const handleClose = () =>{
        setOpen(false);
    }

    const apply = async () => {
		if(tab == 0){
			saveSystem();
		} else {
			if(values.code != undefined && values.code != "Enter a code"){
				const snapshot = await firestore.collection('guests').where("inviteID", "==", values.code).get();
				if(snapshot.empty){
					//pop up a dialog that the invitation couldn't be found
				} else {
					snapshot.forEach( async (doc) => {
						var inviteData = doc.data();
						if(inviteData.invitee == "pending" && inviteData.state == undefined){
							const sys = await(firestore.collection('systems')).doc(inviteData.system).get();
							if(!sys.isEmpty){
								const sysData = sys.data();
								const guests = sysData.guests;
								var newGuests = [];
								if(guests){
									newGuests = [ ...guests, currentUser.uid ]
								} else {
									newGuests.push(currentUser.uid);
								}
								var guestPerms = sysData.permissions;
								if(guestPerms == undefined) guestPerms = {};
								guestPerms[currentUser.uid] = inviteData.permissions;
								firestore.collection('systems').doc(inviteData.system).update({ permissions: guestPerms, guests: newGuests});
							}
							firestore.collection('guests').doc(doc.id).update({invitee: currentUser.uid, state: 'used' });
						}
						
					});
					
				}
				
			}
		}
        setOpen(false);
    };

	const tabChange = (evt, newValue) => {
		setTab(newValue);
	}

    return(
        <div>
            <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{title ? (title): ("Edit System")}</DialogTitle>
            <DialogContent>
			
			
				
				
					{ newsystem ?  
					 	<Tabs centered value={tab} onChange={tabChange} >
						<Tab label="New System" {...a11yProps(0)}></Tab>
						<Tab label="I Have a Code" {...a11yProps(1)}></Tab>
						</Tabs>
					: 
					<Tabs centered value={tab} onChange={tabChange} >
					<Tab label="Existing System" {...a11yProps(0)}></Tab>
					</Tabs>
					}
				
			
			<TabPanel value={tab} index={0}>
				<form>
					<TextField fullWidth required
						id="standard-name"
						label="Name"
						value={values.name}
						onChange={handleChange('name')}
						margin="normal"
						variant="outlined"
						
					/>
					<TextField fullWidth
						id="standard-location"
						label="Location"
						value={values.location}
						onChange={handleChange('location')}
						margin="normal"
						variant="outlined"
					/>
					<TextField fullWidth
						id="standard-numcolors"
						label="Number of Colors"
						value={values.num_colors}
						onChange={handleChange('num_colors')}
						margin="normal"
						variant="outlined"
						type="number"
					/>
				</form>
				
			</TabPanel>
			
			<TabPanel value={tab} index={1}>
				<Typography>Enter your Invitation Code</Typography>
				<form>
				<TextField fullWidth required
						id="standard-code"
						label="Invitation Code"
						value={values.code}
						onChange={handleChange('code')}
						margin="normal"
						variant="outlined"
						
					/>
				</form>
			</TabPanel>
			
            </DialogContent>
            <DialogActions>
				<Button onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={apply} color="secondary">Save</Button>
            </DialogActions>
            </Dialog>
        </div>
    );

}


export default SystemEditor;