import React from "react";

const Account = () =>{

	return(
		<div>
			<h1>Account</h1>
		</div>
	);

}

export default Account;