
import firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';

var firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER,
	  appId: process.env.REACT_APP_APP_ID,
	  measurementID: process.env.REACT_APP_MEASUREMENT_ID
  };
  // Initialize Firebase
if(!firebase.apps.length){
  firebase.initializeApp(firebaseConfig);
}
const firestore = firebase.firestore();

let FieldValue = firebase.firestore.FieldValue;

export default firebase;
export  {firestore, FieldValue}; 