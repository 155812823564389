import React, { useEffect, useState } from 'react';
import { DateTime } from "luxon";
import { RRule, RRuleSet, rrulestr } from 'rrule'
import {MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';


import { Box, Typography,
	TextField, 
	Button, 
	Dialog, 
	DialogContent, 
	DialogTitle,
	DialogContentText, 
	DialogActions, 
	Slider, 
	Divider, 
	Grid,
	Select,
	MenuItem,
	InputLabel, FormGroup, FormControlLabel, FormLabel, Checkbox,
	Table, TableCell,TableRow, TableContainer,
	Radio
} from '@material-ui/core';
import { FastRewind } from '@material-ui/icons';
import RRuleEditor from './RRuleEditor';

export default function RuleEditor ({ rule_string, save }){
	const [ rule, setRule ] = useState({});
	const done = () => {
		save( rule.toString );
	}

	useEffect( () => {
		if( rule_string && rule_string.length){
			setRule(rrulestr(rule_string));
		} else {
			const r = new RRule(
				{
					freq: RRule.MONTHLY,
					interval: 1,
					dtstart: new Date(),
				}
			);
			setRule(r)
			console.log(r);
		}
	} , [ rule_string ]);
	const frequencies  = [ 
		{ name: "Year", val: RRule.YEARLY} , 			
		{ name: "Month", val: RRule.MONTHLY}, 
		{ name: "Week", val: RRule.WEEKLY},
		{ name: "Day", val: RRule.DAILY},
		{ name: "Hour", val: RRule.HOURLY},
		{ name: "Minute", val: RRule.MINUTELY}
	];
	const deltas  = [ 
		{ name: "First", val: 1} , 			
		{ name: "Second", val: 2}, 
		{ name: "Third", val: 3},
		{ name: "Fourth", val: 4},
		{ name: "Last", val: -1}
	];
	const days = [ 
		{ name: "Monday", val: RRule.MO} , 			
		{ name: "Tuesday", val: RRule.TU}, 
		{ name: "Wednesday", val: RRule.WE},
		{ name: "Thursday", val: RRule.TH},
		{ name: "Friday", val: RRule.FR},
		{ name: "Saturday", val: RRule.SA},
		{ name: "Sunday", val: RRule.SU}

	];
	let months = [ 
		{ name: "January", val: "jan"} , 			
		{ name: "February", val: "feb"}, 
		{ name: "March", val: "mar"},
		{ name: "April", val: "apr"},
		{ name: "May", val: "may"},
		{ name: "June", val: "june"},
		{ name: "July", val: "july"},
		{ name: "August", val: "aug"},
		{ name: "September", val: "sept"},
		{ name: "October", val: "oct"},
		{ name: "November", val: "nov"},
		{ name: "December", val: "dec"}

	];
	let byDays = [ 
		{ name: "Monday", val: "byweekdayMO"} , 			
		{ name: "Tuesday", val: "byweekdayTU"}, 
		{ name: "Wednesday", val: "byweekdayWE"},
		{ name: "Thursday", val: "byweekdayTH"},
		{ name: "Friday", val: "byweekdayFR"},
		{ name: "Saturday", val: "byweekdaySA"},
		{ name: "Sunday", val: "byweekdaySU"}

	];

	const buildOptions = () => {
        var arr = [];
        for (let i = 1; i <= 31; i++) {
            arr.push(<option key={i} value="{i}">{i}</option>)
        }
        return arr; 
	}
	
	const generateFrequencyList = () =>{

		let list = [];
		frequencies.map( (val) => { list.push(<MenuItem value={val.val}>{val.name}</MenuItem>); } );
		return list;
	}
	const genWeekStart = () => {
		let list = [];
		days.map( (val) => { list.push(<MenuItem value={val.val}>{val.name}</MenuItem>); } );
		return list;
	}
	const genByWeekday = () =>{
		
		let list = [];
		byDays.map( (val) => { list.push(<FormControlLabel label={val.name} control={<Checkbox name={val.val} />} ></FormControlLabel>); } );
		return list;
	}
	const genByMonth = () => {
		
		let list = [];
		months.map( (val) => { list.push(<FormControlLabel label={val.name} control={<Checkbox name={val.val} />} ></FormControlLabel>); } );
		return list;
	}
	const handleFrequency = (evt) => {
		var r = RRule.fromString( rule.toString());

		r.freq = evt.target.value;
		setRule(r);
	}

	const handleInterval = (evt) => {
		var r = RRule.fromString( rule.toString());
		r.interval = evt.target.value;
		setRule(r);

	}
	const handleByMonth = (evt) => {
		
	}

	const handleChange = name => event => {
	
	}
	const handleStartChange = () =>{

	}

	const handleEndChange = () => {

	}

	const handleRRule = ( val )=> {
		console.log(val);
	}
	return(
			<RRuleEditor></RRuleEditor>
	);
}