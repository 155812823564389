import React , { useState, useEffect } from 'react';

import {Card, CardHeader, CardContent, CardActions, Divider, Tooltip, IconButton, CardActionArea, Avatar, Typography} from '@material-ui/core';
import { Edit, Delete, AccessTime, CalendarToday } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';

import SVGColorBlock from './SVGColorBlock';
import ScheduleEditor from './ScheduleEditor';
import { RRule } from 'rrule';
import moment from 'moment';
import { useCurrentSystem } from './context/SystemContext';

const useStyles = makeStyles(theme => ({
	
	addBox: {
	  minHeight: 140,
	  marginTop: 20,
	  marginBottom: 20
	},
	actionCard: {
		justifyContent: 'center',
	},
	scheduleCard:{

	},
	avatar: {
		backgroundColor : '#ded',
		
	}
  
  }));

const priority_list = ["Highest", "High", "Low", "Lowest"];

const getUTCDate = (date) => {
	const d = new Date(date)
	return new Date(
		Date.UTC(
			d.getUTCFullYear(),
			d.getUTCMonth(),
			d.getUTCDate(),
			d.getUTCHours(),
			d.getUTCMinutes(),
			d.getUTCSeconds()
		)
	)
}
function ScheduleCard ({ schedule, deleteSchedule, scenes, guest }){
	
	const classes = useStyles();
	const {currentSystem} = useCurrentSystem();
	const [rruleState, setRruleState] =useState({
		freq: 1,
		dtstart: '',
		until: '',
		count: 0,
		interval: 1,
		wkst: 0,
		byweekday: [],
		bymonth: []
	});
	const [ startDateText, setStartDateText ] = useState("");
	const [ startTimeText, setStartTimeText ] = useState("");
	const [editing, setEditing ] = useState(false);
	const [repeatText, setRepeatText ] = useState("");
	

	useEffect( () =>{
		
		if( schedule) {
			setStartDateText( moment(schedule.performAt.toDate()).local().format('MMMM Do YYYY'));
			setStartTimeText( moment(schedule.performAt.toDate()).local().format('h:mm:ss a'));
		}
		if( schedule.repeats && schedule.r_rule && schedule.performAt){	
			var r_fixed = {...schedule.r_rule};
			// use the schedule's start date/time for the rrule's start time:
			r_fixed.dtstart = moment(schedule.performAt.toDate()).local().format('YYYY-MM-DDTHH:mm');
			if(r_fixed.until){
				var ts = r_fixed.until.toDate();
				ts = moment(ts).local().format('YYYY-MM-DD');
				r_fixed.until = ts;
			}
			setRruleState(r_fixed);
			
		} else {
			setRepeatText ("");
		}
	}, [schedule]);

	useEffect( () =>{
		console.log(rruleState)
		const rule = { ...rruleState }
		if ( rruleState.byweekday !== undefined && !rruleState.byweekday.length) {
			delete rule.byweekday
		}
		if (rruleState.bymonth !== undefined && !rruleState.bymonth.length) {
			delete rule.bymonth
		}
		if (rruleState.dtstart) {
			rule.dtstart = getUTCDate(rruleState.dtstart)
		} else {
			delete rule.dtstart
		}
		if(rruleState.count != undefined && rruleState.count > 0){
			delete rule.until
		} else {
			
			if (rruleState.until) {
				delete rule.count;
				rule.until = getUTCDate(rruleState.until)
			} 
			
		}
		if( rule.count === 0){
			delete rule.count;
		}
		if(rruleState.byweekday == undefined){
			rule.byweekday = [];
		}
		if ( rruleState.byweekday && rruleState.byweekday.length) {
			rule.byweekday = rruleState.byweekday.map((day) => RRule[day])
		}
		const rruleObj = new RRule(rule);
		if ( rruleState.byweekday && rruleState.byweekday.length) {
			rule.byweekday = rruleState.byweekday.map((day) => { return day;})
		}
		setRepeatText("Repeats "+ rruleObj.toText());
	 },[rruleState]);

	const editSchedule = (event) => {
		setEditing(true);
	}

	const doDeleteSchedule = (event) => {
		deleteSchedule(schedule);
	}

	const doneEdit = (event) => {
		setEditing(false);
	}

	const findScene = () =>{
		if(schedule.scene == 0){
			
			return(<div>
					<SVGColorBlock size={100} data={ [{r:0, g:0, b:0}]} fill='blue'></SVGColorBlock>
					<Typography>{"Blackout"}</Typography>
					<Divider></Divider>
				 </div>);
		}
		for(var i = 0; i < scenes.length; i++){
			
			if(scenes[i].uid == schedule.scene){
				var scene = scenes[i];
				return(
				 
				 <div>
					<SVGColorBlock size={100} data={scene.colors} fill='blue'></SVGColorBlock>
					<Typography>{scene.name}</Typography>
					<Divider></Divider>
				 </div>);
			}
		}
		return( <Typography>Scene Not Found</Typography>)
	}
	return (
		<div>
		<Card className={classes.scheduleCard}>
			
				<CardHeader 
						title={schedule.name}
						avatar={ <Avatar className={classes.avatar}>
							{ schedule.repeats ? <CalendarToday /> : < AccessTime />}
							
							</Avatar>}> 
				</CardHeader>
				<Divider></Divider>
				
				<CardActionArea>
					<CardContent>
						{findScene()}
						{ schedule.repeats ? 
							<>
							<Typography>{"From"} </Typography>
							<Typography>{startDateText}</Typography>
							<Typography>{startTimeText}</Typography>
							<br></br>
							<Typography>{ repeatText }</Typography>
							<br></br>
							<Typography>{"Priority: " + (schedule.priority == undefined ? "Highest" : priority_list[schedule.priority])}</Typography>
							<br></br>
							<Divider></Divider>
							<br></br>
							<Typography>{ "Status: " + (schedule.status == "init" ? "Waiting to run." : schedule.status )}</Typography>
							{ schedule.runCount && 
								<Typography>{ "Run " + schedule.runCount + " Times "}</Typography>
							}
						
							
							</>
							:
							<>
								<Typography>{"Next Run"}</Typography>
								<Typography>{startDateText}</Typography>
								<Typography>{startTimeText}</Typography>
								<br></br>
								
								<Typography>{ schedule.repeat ? "Repeat: Every " + schedule.repeat + " min" : "Doesn't repeat." }</Typography>
								<br></br>
								<Typography>{"Priority: " + (schedule.priority == undefined ? "Highest" : priority_list[schedule.priority])}</Typography>
								<Divider></Divider>
								<br></br>
								<Typography>{"Status: " + schedule.status}</Typography>
							</>
							
							
						}
						{
							
						}
						
					</CardContent>
				</CardActionArea>
			
			<Divider></Divider>
			<CardActions className={classes.actionCard}>
				<Tooltip title="Edit Schedule">
					<IconButton onClick={ editSchedule}>
						<Edit />
					</IconButton>
				</Tooltip>
				<Tooltip title="Delete Schedule">
					<IconButton  onClick={ doDeleteSchedule }>
						<Delete />
					</IconButton>
				</Tooltip>
			</CardActions>
		</Card>
		{ editing &&
			<ScheduleEditor open={editing} schedule={schedule} scenes={scenes} doneEdit={doneEdit}></ScheduleEditor>
		}
		</div>
	);
}

export default ScheduleCard;