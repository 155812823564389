
import React , { useState } from 'react';
import TopBanner from './common/TopBanner';
import SignInForm from './common/SignInForm';

import icon from './simulcasticon.svg';

const SignIn = ( {history} ) => {
	return(
		<div>
			<TopBanner title="Sign in to Simulcast" history={history} icon={icon} ></TopBanner>	
			<SignInForm history={history} icon={icon}></SignInForm>
		</div>
	);
}

export default SignIn;