import React , { useState, useEffect } from 'react';

import {Card, CardHeader, CardContent, CardActions, Divider, Tooltip, IconButton, Badge, Typography, Avatar } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { Edit, Delete, Router } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    
    addBox: {
      minHeight: 140,
      marginTop: 20,
      marginBottom: 20
    },
    actionCard: {
        justifyContent: 'center',
    },
    invisible :{
      color: 'transparent',
    },
    sceneAvatar: {
      backgroundColor : '#fdd',
    }

  }));

  const StyledBadge2 = withStyles(theme => ({
    badge: {
      backgroundColor: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid #44b700',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
   
  }))(Badge);

function GatewayCard({gateway, editGateway, deleteGateway, guest}) {
    
    const doEditGateway = () => {
      editGateway(gateway);
    }

    const doDeleteGateway = () => {
      deleteGateway(gateway);
    }

    const classes = useStyles();

    return(
        <div>
            
            <Card style={{ margin:'24px'}} >
                <CardHeader title={gateway.name} 
                          subheader={gateway.uid}
                          action={<IconButton><Router className={classes.invisible} /></IconButton>}
                          avatar={<Avatar className={classes.sceneAvatar}><Router /></Avatar>}>
                
                </CardHeader>
                <Divider>
                </Divider>
                <CardContent>
                    <Typography>Status</Typography>
                    {gateway.online ? (<>
                        <StyledBadge2 variant="dot">
                            <Typography>On Line</Typography>
                        </StyledBadge2>
                        <Typography>{"Load: " + (gateway.state ? gateway.state.cpuLoad : "Waiting") }</Typography>
                        <Typography>{"Memory: " + (gateway.state ? gateway.state.memoryUsage : "Waiting")}</Typography>
                        
                        </>)
                    : 
                    (<Badge variant="dot" color="error"><Typography>"Off Line"</Typography></Badge>) }
                </CardContent>
                <Divider>
                </Divider>
                <CardActions className={classes.actionCard}>
                    <Tooltip title="Edit Gateway Settings">
                        <IconButton onClick={doEditGateway}>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Gateway">
                        <IconButton disabled={guest} onClick={doDeleteGateway}>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </CardActions>
            </Card>
       
        </div>
    );

}

export default GatewayCard;