import React, { useEffect, useState } from 'react';
import {MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker} from '@material-ui/pickers';
import moment from 'moment';
import 'moment-timezone';
import MomentUtils from '@date-io/moment';
import {RRule} from 'rrule';
import SVGColorBlock from './SVGColorBlock';

import { Box, Typography,
	TextField, 
	Button, 
	Dialog, 
	DialogContent, 
	DialogTitle,
	DialogContentText, 
	DialogActions, 
	Slider, 
	Divider, 
	Grid,
	Select,
	MenuItem,
	InputLabel,
	FormControlLabel, 
	Checkbox
} from '@material-ui/core';

import { firestore } from './common/firebase';

import fb from 'firebase/app'
import 'firebase/firestore'

import RuleEditor from './RuleEditor';
import { useCurrentSystem} from './context/SystemContext';
import RRuleEditor from './RRuleEditor';

function ScheduleEditor({scenes, schedule, open, doneEdit}){

	const { currentSystem } = useCurrentSystem();

	const [values, setValues] = React.useState({ name: "New Schedule", priority: 0 });
	const [r_rule, setR_Rule ] = useState(undefined);
	const [r_rulen, setR_RuleN ] = useState(undefined);
	const [selectedDate, handleDateChange] = useState(new Date());
	const [newSchedule, setNewSchedule] = useState(false);
	const [editingRule, setEditingRule ] = useState(false);



	useEffect( () => {
		//const { colors, ...rest } = scene;
		setR_RuleN(undefined);
		console.log(schedule);
		if(schedule){
			setNewSchedule(false);
			var d = schedule.performAt.toDate();
			handleDateChange(moment(d).local());	
			if(schedule.priority == undefined){
				schedule.priority = 0;
			}
			if( !schedule.r_rule){
				if(schedule.repeats && schedule.repeat && schedule.minutes == undefined){
					schedule.minutes = true;
				}
			}
			setValues(schedule);
			// convert the timestamps into local times:
			if(schedule.r_rule){
				var r_fixed = {...schedule.r_rule};
				// use the schedule's start date/time for the rrule's start time:
				r_fixed.dtstart = moment(d).local().format('YYYY-MM-DDThh:mm');
				if(r_fixed.until){
					var ts = r_fixed.until.toDate();
					ts = moment(ts).local().format('YYYY-MM-DDThh:mm');
					r_fixed.until = ts;
				}
				setR_Rule(r_fixed);
			} else {
				
			}
		} else {
			var sch = { repeats: false };
			sch.name = "New Schedule";
			if(currentSystem){
				sch.system = currentSystem.uid;
			}
			setNewSchedule(true);
			setValues(sch);
		}
		

	}, [schedule] );


	const saveSchedule = async() => {

		// first fix up thwe date and time:
		var fixed_date = moment(selectedDate).seconds(0); // always clip seconds to 0
		const d = new Date(fixed_date);
		const normalized_date = new Date(
					Date.UTC(
						d.getFullYear(),
						d.getMonth(),
						d.getDate(),
						d.getHours(),
						d.getMinutes(),
						d.getSeconds()
					));
				
		fixed_date = fixed_date.tz('America/Los_Angeles').toDate();
		
		console.log(fixed_date);
		const sch = {...values};
		sch.performAt = fb.firestore.Timestamp.fromDate( fixed_date );
		sch.normalizedStart = normalized_date.toISOString();
		if( r_rulen != undefined && sch.repeats){
			sch.status = "waiting";
			sch.r_rule = r_rulen;
			
		} else {
			sch.status = "scheduled";
			if( sch.repeats === undefined ){
				sch.repeats = false;
			}
			delete sch.r_rule;
		}
		
		if(sch.minutes && sch.r_rule){
			delete sch.r_rule;
		}
		if(sch.r_rule != undefined && sch.r_rule.count === 0){
				delete sch.r_rule.count;
		}
		if(sch.r_rule != undefined && sch.r_rule.until !== undefined){
			const d = sch.r_rule.until.toDate();
			const normalized_until = new Date(
				Date.UTC(
					d.getFullYear(),
					d.getMonth(),
					d.getDate(),
					d.getHours(),
					d.getMinutes(),
					d.getSeconds()
				));
			sch.normalizedUntil = normalized_until.toISOString();	
		} else {
			delete sch.normalizedUntil;
		}
		sch.init = true; // signal the server to re-process the schedule
		//save it
		if(newSchedule){
			await firestore.collection('schedules').add(sch);
		} else {
			await firestore.collection('schedules').doc(sch.uid).update(sch);
		}
		//const update = { ...values, colors: cols };
		//firestore.collection('schedules').doc(scene.uid).update(update);
	}

	const handleChange = name => event => {
		const newVals = { ...values, [name]: event.target.value }
		setValues(newVals);
	};

	const handleCheckChange = name => event => {
		const newVals = { ...values, [name]: event.target.checked }
		setValues(newVals);
	}
	
	const handleClose = () =>{
		if(editingRule){
			setEditingRule(false);
		} else {
			doneEdit();
		}
	}

	const apply = () => {
		saveSchedule().then(
			doneEdit()
			);
	};

	const generateSceneList = () =>{
		
		let scene_list = [];
		scene_list.push(<MenuItem value={0}>{"Blackout"}</MenuItem>);
		for( let i = 0; i<scenes.length; i++){
			scene_list.push(
				<MenuItem value={scenes[i].uid}>
					<SVGColorBlock 
						size={24} 
						data={scenes[i] ? scenes[i].colors : [ {r: 0, g:0, b:0 }]} 
						fill='blue'>
					</SVGColorBlock>
					{scenes[i].name}
				</MenuItem>);
		}
		return scene_list;
	}
	const generatePriorityList = () =>{
		let pri_names = [ "Highest", "High", "Low", "Lowest"];
		let pri_list = [];
		for( let i = 0; i<5; i++){
			pri_list.push(<MenuItem value={i}>{pri_names[i]}</MenuItem>);
		}
		return pri_list;
	}

	const rruleChanged = (val) =>{
		if(val.state){
			const rrule = { ...val.state };
			if(rrule.dtstart){
				// add a zone agnostic local time:

				rrule.dtstart = moment(val.state.dtstart).seconds(0);				
				rrule.dtstart = rrule.dtstart.tz('America/Los_Angeles').toDate();
				rrule.dtstart = fb.firestore.Timestamp.fromDate(rrule.dtstart);
			}
			if(rrule.until){
				rrule.until = moment(val.state.until).seconds(0);
				const d = new Date(rrule.until);
				rrule.until = rrule.until.tz('America/Los_Angeles').toDate();
				rrule.until = fb.firestore.Timestamp.fromDate(rrule.until);
			}
			
			setR_RuleN(rrule)
		}
	}

	
	return(
		<div>
			<Dialog margin={1} open={open} onClose={handleClose}>
				
				<DialogTitle>Edit Schedule</DialogTitle>
				<DialogContent>
					<form>
					<TextField fullWidth required
						id="standard-name"
						label="Name"
						value={values.name}
						onChange={handleChange('name')}
						margin="normal"
						variant="outlined"
						
					/>
					<MuiPickersUtilsProvider utils={MomentUtils}>
					<Grid container justify="space-around">
						<KeyboardDatePicker
							disableToolbar
							variant="inline"
							format="MM/DD/yyyy"
							margin="normal"
							id="date-picker-inline"
							label="Date"
							value={selectedDate}
							onChange={handleDateChange}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
						/>
						<KeyboardTimePicker
							margin="normal"
							id="time-picker"
							label="Time"
							value={selectedDate}
							onChange={handleDateChange}
							KeyboardButtonProps={{
								'aria-label': 'change time',
							}}
						/>
						
						
					</Grid>
					</MuiPickersUtilsProvider>
					
					<InputLabel id="scene-label">Scene</InputLabel>
					<Select fullWidth
						label="Scene"
						labelId="scene-label"
						id="schedule-scene-select"
						value={values.scene}
						onChange={handleChange('scene')}
						>
							{generateSceneList()}
					</Select>
					<InputLabel id="priority-label">Priority</InputLabel>
					<Select fullWidth
						label="Priority"
						labelId="priority-label"
						id="schedule-priority-select"
						value={values.priority}
						onChange={ handleChange('priority') }
						>
							{generatePriorityList()}
					</Select>
					</form>
					<br></br>
					
					<FormControlLabel
						control={<Checkbox
							name='rule'
							checked={values.repeats}
							onChange={ handleCheckChange('repeats')}
						/>}
						label='Repeats'
					/>
					{ values.repeats &&
						<>
						<FormControlLabel
							control={<Checkbox
								name='minutes'
								checked={values.minutes}
								onChange={ handleCheckChange('minutes')}
							/>}
							label='Minute based Repeat'
						/>
						{
							values.minutes &&
							
							<>
								<InputLabel id="repeat-label">Repeat</InputLabel>
								<TextField fullWidth required
									id="standard-repeat"
									label="Repeat (Minutes)"
									value={values.repeat}
									onChange={handleChange('repeat')}
									margin="normal"
									variant="outlined"
									
								/>
							</>
							
						}
						{ !values.minutes && 
						<RRuleEditor schedule={values} value={r_rule} numeric={values.repeat} onChange={rruleChanged} ></RRuleEditor>	
						}
						
						</>
					} 
					
					
					
				</DialogContent>


				
				<DialogActions>
					<Button variant="contained" onClick={handleClose} color="secondary">Cancel</Button>
					<Button variant="contained" onClick={apply} color="secondary">Save</Button>
				</DialogActions>
				


			</Dialog>
			
		</div>
	);
}


export default ScheduleEditor;
