import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {    Button, 
	Grid, 
	Card, 
	CardHeader, 
	CardContent, 
	CardActions, 
	Divider, 
	Box, 
	Typography,
	Icon,
	Toolbar,
	Link,
	AppBar, 
	} from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { Redirect } from 'react-router-dom';
import logo from './simulcastlogo.svg';
import Footer from './common/Footer'
import {useAuth } from './common/Auth';

import BigLogo from './img/biglogo.svg';
const useStyles = makeStyles(theme => ({
   
	hero: {
		minHeight: '85%',
		width: '100vw',
		textAlign: 'center',
   	},
   	heroMain: {
		width: '100%',
		height: '100vh',
		background: `url(${BigLogo})` + " no-repeat center center ",
	},
	heading: {
		padding: 10,
	 	margin: 10,
	},
	subhead :{

	},
   	landinglogo :{
		width: '75vw',
	},
	pricing: {
		flexGrow: 3,
	},
	product:{
		flexGrow: 3,
	   
	},
	actionCard: {
		justifyContent: 'center',
	},
	footer: {

	},
	scrollindicator:{
		fontSize: 90,
		bottom: 100,
	},
	link: {
		color: 'black',
	},
	callToAction: {
		width: 300,
		height: 80,
		background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
	},
	callToActionLabel: {
		color: 'white',
		fontSize: 24,
		//textTransform: 'capitalize',
	}
  }));



const CallToAction = ({children, ...rest}) => {
	const classes = useStyles();
	return(
		<Button {...rest} classes={{ root: classes.callToAction, label: classes.callToActionLabel}}>{children}</Button>
	);
}
const Hero = ({ bg, color, children, showScroll }) => {
	const classes = useStyles();
	return(
			<div className={classes.heroMain} style={{backgroundColor: bg, color: color}}>
				<Box display="flex"  className={classes.hero}  alignItems="center" justifyContent="center">
					<Box>{children}</Box>
				</Box>
				{ showScroll && <ScrollHint />}
			</div>
		);
}

const Features = ({children}) => {
	const classes = useStyles();
	return(
				<>
				<Heading>Features</Heading>
				<Grid container className={classes.pricing} spacing={2} alignItems="stretch" justify="center">
					{children}
				</Grid>
				</>
	);
}
const Feature = ({ feature, featuresub, sellingPoints,}) => {
	const classes = useStyles();
	return( <>
			<Grid item className={classes.product} xs={12} sm={4} >
				<Card style={{ height:"100%"}}>
					<CardHeader title={feature} subheader={ featuresub }></CardHeader>
					<Divider></Divider>
					<CardContent style={{height: "65%"}}>
						{ sellingPoints && sellingPoints.map( (item) => (
							<><Typography>{item}</Typography><br></br></>
						))}
					</CardContent>
					<CardActions className={classes.actionCard}></CardActions>
				</Card>
			</Grid>
			</>
		);
}
const Pricing = ({children}) => {
	const classes = useStyles();
	return(
		<>
			<Heading>Pricing</Heading>
			<Grid container className={classes.pricing} spacing={2} 
						alignItems="stretch" justify="center">
				{children}
			</Grid>
		</>
	);
}
const PricingTier = ({ tierName, price, billingType, sellingPoints, bg, signupAction, signupTag}) => {
	const classes = useStyles();
	return( <>
			<Grid item className={classes.product} xs={12} sm={4} >
				<Card style={{ background: bg, height:"100%", color:'black'}}>
					<CardHeader style={{ background: 'darkgray', color:'black'}}title={tierName} subheader={ price + " " + billingType}></CardHeader>
					<Divider></Divider>
					<CardContent style={{height:"65%", color:'black'}}>

						{ sellingPoints.map( (item) => (
							<><Typography>{item}</Typography></>
						))}
					</CardContent>
					<CardActions className={classes.actionCard}>
						<Button id={signupTag} onClick={signupAction}variant="contained">Subscribe Now</Button>
					</CardActions>	
				</Card>
			</Grid>
			</>
		);
}
const Heading = ({textAlign, children }) => {
	const classes = useStyles();
	
	return( <Typography className={classes.heading } variant="h3" style={{ textAlign: textAlign  }}>{children}</Typography> );
}
const Subhead = ({children}) => {
	const classes = useStyles();

	return( <Typography className={ classes.subhead } variant="h5">{children}</Typography> );
}

const ScrollHint = () =>{
	const classes = useStyles();
	return(<><Icon ><KeyboardArrowDown className={classes.scrollindicator} /></Icon> </>);
}



const Landing = ({history, children}) => {
	const classes = useStyles();
	const currentUser = useAuth();

	if (currentUser) {
		return <Redirect to="/app" />;
	}
	const signup = (event) => {
		history.push("/signup");
	}
	return( <div style={{ top: 0, position:"absolute" }}>
			<AppBar position="fixed"  style={{ background: 'transparent', boxShadow: 'none'}}>
				<Toolbar><Typography className={classes.product}> </Typography><Link className={classes.link} href="/login">Sign In</Link></Toolbar>
			</AppBar>
			<Hero color="black" bg="lightgray" showScroll={true}>
				<img src={logo} className={classes.landinglogo} />
				<Heading>Communicate with color.</Heading>
				<Subhead>Around the corner or around the world.</Subhead>
				<br></br>
				<Box>
					<CallToAction href="/signup" margin={2} padding={3} variant="contained">Get Started</CallToAction>
					
				</Box>
			</Hero>
			<Hero color="black" bg="#96D7EB" showScroll={true}>
				<Heading textAlign="center">
					You've invested in Color.
				</Heading>
				<Subhead>
					Now turn it into a valuable tool.
				</Subhead>
				<br></br>
				<Subhead>Simulcast turns colored lighting features into connected, responsive social engagement platforms.</Subhead>

			</Hero>
			
			<Hero bg="#839CB5" color="black" showScroll={true}>
				<Features>
					<Feature feature="Color First" sellingPoints={ [ 
							"Leave your customer with a simple color interface", 
							"Create your own palettes, or use Simulcast's curated library."]}></Feature>
					<Feature feature="Connected" sellingPoints={ [
							"Built In Cellular Connection", 
							"Integrates with existing controllers."]}></Feature>
					<Feature feature="Synchronized" sellingPoints={ [
							"Connect and synchronize multiple sites.", 
							"Integrates with existing controllers over Ethernet."]}></Feature>
				</Features>
			</Hero>
			<Hero id="pricing" bg="#B1E1E9" color="black">
				<Pricing>
					<PricingTier bg="#f2f1ed"
						tierName="Premium"
						price="$99"
						billingType="Monthly"
						sellingPoints={[
							'Unlimited Sites',
							'Realtime manual control',
							'Ethernet connectivity',
							'Pay per use remote proxy',
							'Email and forum support'
						]}
						signupAction={signup}
						signupTag="1">
						
					</PricingTier>
					<PricingTier bg="#e3e1dc"
						tierName="Premium +"
						price="$149"
						billingType="Monthly"
						sellingPoints={[
							'Unlimited Sites',
							'Realtime, manual control',
							'Scheduling',
							'10000 API calls per month',
							'Email and Forum support',
							'Some Telemetry',
							'10 free remote proxy sessions per month',
							'Pay per use remote access after the first 10',
							'Cellular + Ethernet'
						]}
						signupAction={signup}
						signupTag="2">
						
					</PricingTier>

					<PricingTier bg="#d9d6ce"
						tierName="Platinum"
						price="$199"
						billingType="Monthly"
						sellingPoints={[
							'Unlimited Sites',
							'Realtime, manual control',
							'Scheduling',
							'1 million API calls per month',
							'1 Custom web app per system',
							'Email, forum, and telephone support',
							'Full Telemetry',
							'Unlimited free remote access sessions',
							'Cellular + Ethernet'
						]}
						signupAction={signup}
						signupTag="1"></PricingTier>
					
				</Pricing>
				
				
			</Hero>
			<Footer></Footer>
			</div>);
}

export default Landing;
