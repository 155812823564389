import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Home, AccountCircle } from "@material-ui/icons/";
import MenuIcon from '@material-ui/icons/Menu';
import {
	Menu,
	MenuItem,
	Divider,
	IconButton,
	Typography,
	Toolbar,
	AppBar
} from "@material-ui/core";

import firebase from "./common/firebase";

import icon from "./simulcasticon.svg";
import logo from "./simulcastlogo.svg";
import { useAuth, useUserExt, useAdminExt, useAgentExt } from "./common/Auth";
import { useCurrentSystem } from "./context/SystemContext";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1
	},
	menuButton: {
		marginRight: theme.spacing(2)
	},
	menuButton: {
		marginRight: 36,
	  },
	menuButtonHidden: {
	display: 'none',
	},
	title: {
		flexGrow: 1
	}
}));

export default function TopNav({ handleOpen, menuopen, history, cl_in }) {
	const classes = useStyles();
	const currentUser = useAuth();
	const { userExt } = useUserExt(currentUser.uid);
	const { userAdmin } = useAdminExt(currentUser.uid);
	const { userAgent } = useAgentExt(currentUser.uid);

	const { setCurrentSystemUID } = useCurrentSystem();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const doSignOut = (e) => {
		e.preventDefault();
		handleClose();
		firebase.auth().signOut();
	};

	const goHome = (e) => {
		e.preventDefault();
		handleClose();
		setCurrentSystemUID("");
		history.push("/app/");
	};

	const doAdmin = (e) => {
		e.preventDefault();
		handleClose();
		history.push("/app/admin");
	};

	const doAgent = (e) => {
		e.preventDefault();
		handleClose();
		history.push("/app/agent");
	};

	return (
		
			<AppBar className={cl_in} color='gray' position="absolute">
				<Toolbar>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="open drawer"
						onClick={handleOpen}
						className={`${ classes.menuButton } ${menuopen && classes.menuButtonHidden}`}
							>
						<MenuIcon />
          			</IconButton>
					{ /*<img src={icon} height={40}></img>*/ }
					<Typography variant="h6" className={classes.title}></Typography>
					<img src={logo} height={40}></img>
					<Typography variant="h6" className={classes.title}></Typography>
					<div>
						<IconButton
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleMenu}
							color="inherit"
						>
							<AccountCircle />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: "top",
								horizontal: "right"
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "right"
							}}
							open={open}
							onClose={handleClose}
						>
							<MenuItem onClick={handleClose}>{currentUser && currentUser.email}</MenuItem>
							<Divider />
							{
							//<MenuItem onClick={handleClose}>Profile</MenuItem>
							//<MenuItem onClick={handleClose}>My account</MenuItem>
							}
							<MenuItem onClick={goHome}>Home</MenuItem>
							
							{ userAgent && userAgent.agentID && !userAgent.revoked && (
								<>
								<Divider />
								<MenuItem onClick={doAgent}>Agent</MenuItem>
								</>
							)}
							{ userAdmin !== undefined && userAdmin && (
								<>
								<Divider />
								<MenuItem onClick={doAdmin}>Admin</MenuItem>
								</>
							)}
							<Divider />
							
							<MenuItem onClick={doSignOut}>Sign Out</MenuItem>
							
						</Menu>
					</div>
				</Toolbar>
			</AppBar>
		
	);
}
