import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { range } from 'moment-range';
import { TrendingUp } from '@material-ui/icons';

const MonthList = ({ input, setMonth }) => {
	let months = [];
	input.map(data => {
		months.push(
			<TableCell
				key={data}
				className="calendar-month"
				onClick={e => {
					setMonth(data);
				}}
			>
				<span>{data}</span>
			</TableCell>
		);
	});
	let rows = [];
	let cells = [];

	months.forEach((row, i) => {
		if (i % 3 !== 0 || i == 0) {
			cells.push(row);
		} else {
			rows.push(cells);
			cells = [];
			cells.push(row);
		}
	});
	rows.push(cells);
	let monthlist = rows.map((d, i) => {
		return <TableRow>{d}</TableRow>;
	});

	return (
		<TableContainer component={Paper}>
			<Table className="calendar-month">
			<TableHead>
				<TableRow>
					<TableCell component="th" colSpan="4">Select a Month</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>{monthlist}</TableBody>
			</Table>

		</TableContainer>
		
	);
};
const YearTable = ({year, getDates, setYear}) => {
	let months = [];
	let nextten = moment()
		.set("year", year)
		.add("year", 12)
		.format("Y");

	let tenyear = getDates(year, nextten);

	tenyear.map(data => {
		months.push(
			<TableCell
				key={data}
				onClick={e => {
					setYear(data);
				}}
			>
				<span>{data}</span>
			</TableCell>
		);
	});
	let rows = [];
	let cells = [];

	months.forEach((row, i) => {
		if (i % 3 !== 0 || i == 0) {
			cells.push(row);
		} else {
			rows.push(cells);
			cells = [];
			cells.push(row);
		}
	});
	rows.push(cells);
	let yearlist = rows.map((d, i) => {
		return <TableRow>{d}</TableRow>;
	});

	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell component="th" colSpan="4">Select a Year</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>{yearlist}</TableBody>
			</Table>
		</TableContainer>
	);
};
const Calendar = () => {

	const [dateObject, setDateObject] = useState(moment());
	const [showCalendarTable, setShowCalendarTable] = useState(true);
	const [showMonthTable, setShowMonthTable] = useState(false);
	const [allMonths, setAllMonths] = useState(moment.months());
	const [showYearNav, setShowYearNav] = useState(false);
	const [selectedDay, setSelectedDay] = useState(null);


	const weekdayShort = moment.weekdaysShort();

	const daysInMonth = () => {
		return dateObject.daysInMonth();
	};
	const year = () => {
		return dateObject.format("Y");
	};
	const currentDay = () => {
		return dateObject.format("D");
	};
	const firstDayOfMonth = () => {
		
		let firstDay = moment(dateObject)
			.startOf("month")
			.format("d"); // Day of week 0...1..5...6
		return firstDay;
	};
	const month = () => {
		return dateObject.format("MMMM");
	};

	const showMonth = (e, month) => {

		setShowMonthTable(true);
		setShowYearNav(false)
		setShowCalendarTable(false);

	};
	const setMonth = month => {
		let monthNo = allMonths.indexOf(month);
		let date_object = Object.assign({}, dateObject);
		date_object = moment(date_object).set("month", monthNo);
		setDateObject(date_object);
		setShowMonthTable(false);
		setShowCalendarTable(TrendingUp);
	};

	const showYearEditor = () => {

		setShowYearNav(true);
		setShowCalendarTable(false);

	};

	const onPrev = () => {
		let curr = "";
		if (showMonthTable == true) {
			curr = "year";
		} else {
			curr = "month";
		}

		setDateObject(moment(dateObject).subtract(1, curr));

	};

	const onNext = () => {
		let curr = "";
		if (showMonthTable == true) {
			curr = "year";
		} else {
			curr = "month";
		}

		setDateObject(moment(dateObject).add(1, curr));

	};
	const setYear = year => {
		// alert(year)
		let date_object = moment(dateObject).set("year", year);

		setDateObject(date_object);
		setShowMonthTable(false);
		setShowYearNav(false);
		setShowCalendarTable(true);

	};

	const onYearChange = e => {
		this.setYear(e.target.value);
	};

	const getDates = (startDate, stopDate) => {
		var dateArray = [];
		var currentDate = moment(startDate);
		var stopDate = moment(stopDate);
		while (currentDate <= stopDate) {
			dateArray.push(moment(currentDate).format("YYYY"));
			currentDate = moment(currentDate).add(1, "year");
		}
		return dateArray;
	}

	const onDayClick = (e, d) => {

		setSelectedDay(d);
		console.log("SELECTED DAY: ", selectedDay);

	};

	let weekdayshortname = weekdayShort.map(day => {
		return <TableCell component="th" key={day}>{day}</TableCell>;
	});
	let blanks = [];
	for (let i = 0; i < firstDayOfMonth(); i++) {
		blanks.push(<TableCell>{""}</TableCell>);
	}

	let days_in_month = [];
	for (let d = 1; d <= daysInMonth(); d++) {
		let current_day = d == currentDay() ? "today" : "";
		
		days_in_month.push(
			<TableCell key={d} onClick={e => {
				onDayClick(e, d);
			}} >
				{d}
			</TableCell>
		);
	}
	var totalSlots = [...blanks, ...days_in_month];
	let rows = [];
	let cells = [];

	totalSlots.forEach((row, i) => {
		if (i % 7 !== 0) {
			cells.push(row);
		} else {
			rows.push(cells);
			cells = [];
			cells.push(row);
		}
		if (i === totalSlots.length - 1) {
			// let insertRow = cells.slice();
			rows.push(cells);
		}
	});

	let daysinmonth = rows.map((d, i) => {
		return <TableRow>{d}</TableRow>;
	});

	return (
		<div>
			<div>
				<Button
					onClick={e => {
						onPrev();
					}}
					
				>{"<"}
				</Button>
				{!showMonthTable && !showYearNav && (
					<Button
						onClick={e => {
							showMonth();
						}}
						
					>
						{month()}
					</Button>
				)}
				<Button
					
					onClick={e => {
						showYearEditor();
					}}
				>
					{year()}
				</Button>

				<Button
					onClick={e => {
						onNext();
					}}
					
				>{">"}</Button>
			</div>
			<div>
				{showYearNav && <YearTable year={year()} getDates={getDates} setYear={setYear} />}
				{showMonthTable && (
					<MonthList input={moment.months()} setMonth={setMonth}/>
				)}
			</div>

			{showCalendarTable && (
				<TableContainer component={Paper} >
					<Table>
						<TableHead>
							<TableRow>{weekdayshortname}</TableRow>
						</TableHead>
						<TableBody>{daysinmonth}</TableBody>
					</Table>
				</TableContainer>
			)}
		</div>
	);

}

export default Calendar;