import React, {
    useEffect,
    useState,
    useContext
} from "react";

import {firestore} from "../common/firebase";



// *** System API ***
/*
system = uid => this.db.doc(`systems/${uid}`);
systems = () => this.db.collection('systems');
usersystems = userid => this.systems().where( "userId", "==", `${userid}`);

gateway = uid => this.db.doc(`gateways/${uid}`);
gatewayconfig = uid => this.db.doc(`gateways/${uid}/config/network`);
gatewaystate = uid => this.db.doc(`gateways/${uid}/config/state`);

gateways = () => this.db.collection('gateways');
systemgateways = sysid => this.gateways().where("system_id", "==", `${sysid}`);

scene = uid => this.db.doc( `scenes/${uid}` );
scenes = () =>this.db.collection('scenes');
systemscenes = sysid =>this.scenes().where("system_id", "==", `${sysid}`);
*/

function useCollection(name, limit) {
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [collection, setCollection] = useState([])
    
    useEffect(
        () => {
            
            const unsubscribe = firestore
                .collection(name)
                .limit(limit)
                .onSnapshot(
                    snapshot => {
                    const sys = [];
                    snapshot.forEach(doc =>
                            sys.push({...doc.data(), uid: doc.id} ),
                        );
                        setCollection(sys.reverse());
                        setLoading(false);
                    } ,
                    err => { 
                        setError(err);
                    }
                )
            return () => unsubscribe()
        },
        [limit]
    )

    return {
        error,
        loading,
        collection,
    };
}


function useAllSystems(limit) {
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [systems, setSystems] = useState([])
    
    useEffect(
        () => {
            
            const unsubscribe = firestore
                .collection('systems')
                .orderBy('createdAt', 'desc')
                .limit(limit)
                .onSnapshot(
                    snapshot => {
                    const sys = [];
                    snapshot.forEach(doc =>
                            sys.push({...doc.data(), uid: doc.id} ),
                        );
                        setSystems(sys.reverse());
                        setLoading(false);
                    } ,
                    err => { 
                        setError(err);
                    }
                )
            return () => unsubscribe()
        },
        [limit]
    )

    return {
        error,
        loading,
        systems,
    };
}

export { useCollection, useAllSystems }
