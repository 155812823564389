import React from "react";
import { Fab, Grid, Box, Tooltip } from "@material-ui/core";
import SystemCard from "./SystemCard";
import { Add } from "@material-ui/icons";
import { useSystems, useGuestSystems } from "./context/SystemContext";

function SystemList({ user, deleteSystem, newSystem, editSystem, forgetSystem }) {

	
	const { error, loading, systems } = useSystems(user ? user.uid : "", 100);
	const { guestSystems } = useGuestSystems( user ? user.uid : "", 100);
	return (
		<div>
			<h1>My Systems</h1>
			<Grid
				container
				direction="row"
				justify="space-evenly"
				alignItems="center"
				spacing={2}
			>
				{systems &&
					systems.map((system, index) => (
						<Grid item key={system.uid}>
							<SystemCard
								index={index}
								system={system}
								editSystem={editSystem}
								deleteSystem={deleteSystem}
							>
							</SystemCard>
						</Grid>
					))}
			</Grid>
			<h1>Shared Systems</h1>
			<Grid
				container
				direction="row"
				justify="space-evenly"
				alignItems="center"
				spacing={2}
			>

				{guestSystems &&
					guestSystems.map((system, index) => (
						<Grid item key={system.uid}>
							<SystemCard
								index={index}
								system={system}
								editSystem={editSystem}
								deleteSystem={forgetSystem}
								forgetSystem={forgetSystem}
								guest={true}
							></SystemCard>
						</Grid>
					))}
			</Grid>
			<Box m={2}>
				<Tooltip title="Add System">
					<Fab variant="round" color="secondary" onClick={newSystem}>
						<Add />
					</Fab>
				</Tooltip>
			</Box>
		</div>
	);
}

export default SystemList;
