import React, { useEffect, useState, useContext } from "react";
import firebase, {firestore} from "./firebase";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
	firebase.auth().onAuthStateChanged( authUser => {
		if (authUser) {
			firestore.collection("users")
			.doc(authUser.uid)
			.get()
			.then(snapshot => {

				const dbUser = snapshot.data();
				// default empty roles
				if(dbUser){
					if (!dbUser.roles) {
						dbUser.roles = [];
					}
					authUser = {
						uid: authUser.uid,
						email: authUser.email,
						emailVerified: authUser.emailVerified,
						providerData: authUser.providerData,
						...dbUser,
					};
				}
				setCurrentUser(authUser);
				
			});
			setCurrentUser(authUser);
		} else {
			setCurrentUser(undefined);
		}	
	});

  }, []);

  return (
    <AuthContext.Provider value={ {currentUser} } >
      {children}
    </AuthContext.Provider>
  );
};
// useAuth Hook!
export const useAuth = () => {
  const { currentUser } = useContext(AuthContext);
  return currentUser;
}

export function useUserExt(uid) {
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [userExt, setUserExt] = useState(null)
    
    useEffect(
        () => {
            
            const unsubscribe = firestore
				.collection('users')
				.doc(uid)
                .onSnapshot(
					doc => {
						setUserExt({...doc.data(), uid: doc.uid});
						setLoading(false);
                    } ,
                    err => { 
                        setError(err);
                    }
                )
            return () => unsubscribe()
        },
        [uid]
    )

    return {
        error,
        loading,
        userExt,
    };
}

export function useAgentExt(uid) {
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [userAgent, setUserAgent] = useState(null)
    
    useEffect(
        () => {
            
            const unsubscribe = firestore
				.collection('agent')
				.doc(uid)
                .onSnapshot(
					doc => {
						if(doc.exists){
                            setUserAgent({...doc.data(), uid: doc.uid});
						    setLoading(false);
                        } else {
                            setUserAgent(null);
                        }
                    } ,
                    err => { 
                        setError(err);
                        setUserAgent(null);
                    }
                )
            return () => unsubscribe()
        },
        [uid]
    )

    return {
        error,
        loading,
        userAgent,
    };
}

export function useAdminExt(uid) {
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [userAdmin, setUserAdmin] = useState(false)
    
    useEffect(
        () => {
            
            const unsubscribe = firestore
				.collection('admin')
				.doc(uid)
                .onSnapshot(
					doc => {
						if(doc.exists){
                            setUserAdmin(true);
						    setLoading(false);
                        } else {
                            setUserAdmin(false);
						    setLoading(false);
                        }
                    } ,
                    err => { 
						setUserAdmin(false);
                        setError(err);
						setLoading(false);
                    }
                )
            return () => unsubscribe()
        },
        [uid]
    )

    return {
        error,
        loading,
        userAdmin,
    };
}