import React from "react";
import { TextField, Grid, Paper, AppBar, Button} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles( theme => ({
	
	main: {
		marginTop: '100px',
		height: '60vh',
	},
	textField: {
		width: 250,
	},
	paper: {
		padding: theme.spacing(3, 2),
	},
	form: {
		padding: theme.spacing(3, 2),
		width: '100%',
	},
}));


//----------------------------------------------------------------------------------
const SignUpForm = ({ handleChange, values, applyFunc }) => {
	const classes = styles();
	return(
		<div>
	
		<Grid className={classes.main} container 
							justify="center"
							alignItems="center"
							>
			<Grid item>
				<Paper className={classes.paper}>
					<form>
					<Grid className={classes.form} container direction="column"
							justify="center"
							alignItems="center"
							spacing={1}>
						<Grid item>
								<TextField
									id="first"
									label="First Name"
									value={values.first}
									className={classes.textField}
									onChange={handleChange('first')}
									autoComplete="given-name"
									margin="normal"
									required
								/>
						</Grid>
						<Grid item>
							<TextField
								id="last"
								label="Last Name"
								value={values.last}
								className={classes.textField}
								onChange={handleChange('last')}
								autoComplete="family-name"
								margin="normal"
								required
							/>
						</Grid>
						<Grid item>
							<TextField
								id="phone"
								label="Telephone Number"
								value={values.phone}
								className={classes.textField}
								onChange={handleChange('phone')}
								autoComplete="tel"
								margin="normal"
								required
							/>
						</Grid>
						<Grid item>
							<TextField
								id="email"
								label="Email"
								value={values.email}
								className={classes.textField}
								onChange={handleChange('email')}
								autoComplete="email"
								margin="normal"
								required
							/>
						</Grid>
						<Grid item>
							<TextField
								id="password"
								label="Password"
								type="password"
								className={classes.textField}
								value={values.password}
								onChange={handleChange('password')}
								margin="normal"
							/>
						</Grid>
						<Grid item>
							<TextField
								id="passwordconfirm"
								label="Confirm Password"
								type="password"
								className={classes.textField}
								value={values.passwordconfirm}
								onChange={handleChange('passwordconfirm')}
								margin="normal"
							/>
						</Grid>
						
						<Grid  item>
							<Button variant="contained" onClick={applyFunc}>Sign Up</Button>
						</Grid>
						
					</Grid>
					</form>
				</Paper>
			</Grid>
		</Grid>
		</div>
	);
}
export default SignUpForm;