import React, { useEffect, useState } from 'react';
import {TextField, Button, 
    Dialog, DialogContent, DialogTitle, DialogActions} from '@material-ui/core';



function ColorEditor({color, open, setOpen, saveColor,  title }){

    const [values, setValues] = React.useState({ name: ""});
    
    useEffect( () => {
        setValues(color);
    }, [color] );


    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };
    
    const handleClose = () =>{
        setOpen(false);
    }

    const apply = () => {
        saveColor(values);
        setOpen(false);
    };

    return(
        <div>
            <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{title ? (title): ("Edit Color")}</DialogTitle>
            <DialogContent>
				<form>
                <TextField fullWidth required
                    id="standard-name"
                    label="Color Name"
                    value={values ? values.name : ""}
                    onChange={handleChange('name')}
                    margin="normal"
                    variant="outlined"
                    
                />
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={apply} color="secondary">Save</Button>
            </DialogActions>
            </Dialog>
        </div>
    );

}


export default ColorEditor;