import React , { useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { CssBaseline,  Box } from '@material-ui/core';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

//import { Route, Switch } from 'react-router';
import './App.css';
import Main from './Main';
import SignIn from './SignIn';
import SignUpFlow from './SignUp';
import Landing from './Landing';
import Agent from './Agent';

import { AuthProvider } from './common/Auth';
import AuthRoute from './common/AuthRoute';

import { useSystems, CurrentSystemProvider } from './context/SystemContext';
import Admin from './Admin';


function App() {
  
  const [currentSystem, setCurrentSystem] = useState( {} );
  const [ editSystem, setEditSystem ] = useState (false);
  
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
		  type: prefersDarkMode ? 'dark' : 'light',
		 
		  primary: {
				main : prefersDarkMode ? '#a3b9c2' : '#a3b9c2' ,
			},
			secondary: {
				main : prefersDarkMode ? '#c2a3a9' : '#c2a3a9',
			},
			background: {
				main: prefersDarkMode ? '#000000' : '#ffffff',
			}
        },
      }),
    [prefersDarkMode],
  );	


  const createNewSystem = async () =>{
	console.log("New System");
	const system = {name: "New System", type:"Default"};
	getSystems();
  }

  const getSystems = async () => {
	//const sys =  await API.graphql(graphqlOperation(query.listSystems, {limit: 100}));
	//setSystems(sys.data.listSystems.items);
	
  }

  const setSystem = (system) => {
	setCurrentSystem(system);
  }

  const doDeleteSystem = (system) =>{
	var sysid = { id: system.id };
	//API.graphql(graphqlOperation( deleteSystem, { input: sysid })).then(
		getSystems();
	   // );
  } 

  const doEditSystem = (system) => {
	setCurrentSystem(system);
	setEditSystem(true);
  }

  const endEditSystem = () => {
	setEditSystem(false);
  }
  return (
	<div className="App">
	<AuthProvider>
	  <CurrentSystemProvider>
	  
	  <ThemeProvider theme={theme}>
	  	<CssBaseline />
		<Box>
		  <Router>
			<Switch>
			  <AuthRoute path="/app" component={Main} />
			  <Route exact path="/login" component={SignIn} />
			  <Route exact path="/signup" component={SignUpFlow} />
			  <Route path="/" component={Landing} />
			</Switch>
		  </Router>
		  
		</Box>
	  </ThemeProvider>
	  </CurrentSystemProvider>
	</AuthProvider>
	</div>
  );
}

export default App;
 