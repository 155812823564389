import React, { useEffect, useState } from 'react';
import { TextField, Button, 
    Checkbox, FormControlLabel,
    Dialog, DialogContent, DialogTitle,DialogContentText, DialogActions} from '@material-ui/core';
import  { useAuth } from './common/Auth'; 
import {firestore, FieldValue} from './common/firebase';


function InviteForm({system, open, setOpen,  title }){

	const [values, setValues] = React.useState({});
	const [ permissions, setPermissions ] = React.useState({
		write: true,
		add: true,
		invite: false,
		delete: false,
		control: true
	});

    const currentUser = useAuth();
    
	const genRandomString = () => {
		const rnd1 = (Math.floor(Math.random()*90000) + 10000);
		const rnd2 = (Math.floor(Math.random()*90000) + 10000);
		const rnd3 = (Math.floor(Math.random()*90000) + 10000);
		return( rnd1 + "-" + rnd2 + "-" + rnd3 )
	}

    const saveInvitation = async() => {
		const guest = { ...values, invitor: currentUser.uid, system: system.uid , permissions: permissions, inviteID: genRandomString(), invitee: "pending" }
		const email = { 
				to: values.email,
				
				template:{ 	
					name: "guestInvitation",
					data: {
						sender: currentUser.first + " " + currentUser.last,
						name: values.name,
						invitecode: guest.inviteID,
						sysname: system.name,
						syslocation: system.location
					}
				}
				};
		firestore.collection('guests').add(guest).then(
			() => firestore.collection('mail').add( email )
		);
		
        
    }

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
	};
	
	const handlePermissions = event => {
        setPermissions({ ...permissions, [event.target.name]: event.target.checked });
    };
    
    const handleClose = () =>{
        setOpen(false);
    }

    const apply = () => {
        saveInvitation();
        setOpen(false);
    };

    return(
        <div>
            <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{title ? (title): ("Send Invitation")}</DialogTitle>
            <DialogContent>
				<form>
                <TextField fullWidth required
                    id="invitation-name"
                    label="Name"
                    value={values ? values.name : ""}
                    onChange={handleChange('name')}
                    margin="normal"
                    variant="outlined"
                    
                />
                <TextField fullWidth
                    id="invitation-email"
                    label="Email Address"
                    value={values ? values.email : ""}
                    onChange={handleChange('email')}
                    margin="normal"
                    variant="outlined"
                />
				<FormControlLabel
					control={
					<Checkbox
						checked={permissions.control}
						onChange={handlePermissions}
						name="control"
						color="secondary"
					/>
					}
					label="Control"
      			/>
				<FormControlLabel
					control={
					<Checkbox
						checked={permissions.add}
						onChange={handlePermissions}
						name="add"
						color="secondary"
					/>
					}
					label="Add"
      			/>
				<FormControlLabel
					control={
					<Checkbox
						checked={permissions.delete}
						onChange={handlePermissions}
						name="delete"
						color="secondary"
					/>
					}
					label="Delete"
      			/>
				<FormControlLabel
					control={
					<Checkbox
						checked={permissions.write}
						onChange={handlePermissions}
						name="write"
						color="secondary"
					/>
					}
					label="Change"
      			/>
				  <FormControlLabel
					control={
					<Checkbox
						checked={permissions.invite}
						onChange={handlePermissions}
						name="invite"
						color="secondary"
					/>
					}
					label="Invite"
      			/>
				</form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={apply} color="secondary">Send</Button>
            </DialogActions>
            </Dialog>
        </div>
    );

}


export default InviteForm;