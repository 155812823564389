import React, {useState, useEffect} from "react";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AuthRoute from './common/AuthRoute';
import { Route, Switch , useRouteMatch } from 'react-router-dom';
import { Link, Typography, Drawer, IconButton, Divider, List, Container, Box, Tooltip } from '@material-ui/core/';
import Hidden from '@material-ui/core/Hidden';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import LayersIcon from '@material-ui/icons/Layers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import AvTimerIcon from '@material-ui/icons/AvTimer';

import {HomeWork, Palette, CalendarToday, AccountCircle, Settings, Router} from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';

import TopNav from "./TopNav";
import { useAuth, useUserExt, useAdminExt, useAgentExt } from "./common/Auth";
import { useCurrentSystem } from "./context/SystemContext";

import Home from './Home';
import Admin from './Admin';
import Agent from './Agent';
import Account from "./Account";

function Copyright() {
	return (
	  <Typography variant="body2" color="textSecondary" align="center">
		{'Copyright © '}
		<Link color="inherit" href="https://phospec.io/">
		  {"Phospec Industries Inc."}
		</Link>{' '}
		{new Date().getFullYear()}
		{'.'}
	  </Typography>);
}
function Version() {
	return (
	  <Typography variant="body2" color="textSecondary" align="center">
		{'V1.0.4.6'}
	  </Typography>);
}

const drawerWidth = 240;


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));
const Main = ( { history } ) => {
	
	const classes = useStyles();
	const [open, setOpen] = React.useState(true);
	const [view, setView ]= React.useState("systems");
	const currentUser = useAuth();
	const { userExt } = useUserExt(currentUser.uid);
	const { userAdmin } = useAdminExt(currentUser.uid);
	const { userAgent } = useAgentExt(currentUser.uid);
	const [guest, setGuest ] = useState(false);

	
	const { currentSystem , setCurrentSystemUID } = useCurrentSystem();

	useEffect ( () =>{
		if( currentSystem && currentUser){ 
			setGuest( currentUser.uid != currentSystem.userId );
		}
	}, [ currentUser, currentSystem]);

	const handleDrawerOpen = () => {
		setOpen(true);
	};
	
	const handleDrawerClose = () => {
		setOpen(false);
	};
	
	const changeView = ( new_view )=>{
		setView(new_view);
		handleDrawerClose();
	}

  	const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
	
	let { path, url } = useRouteMatch();

	const drawer = () =>{
		return(
			<>
			<div className={classes.toolbarIcon}>
			{
				open ? (
					<Tooltip title="Close Menu">
					<IconButton onClick={handleDrawerClose}>
						<ChevronLeftIcon />
					</IconButton>
					</Tooltip>
				) : (
					<Tooltip title="Open Menu">
					<IconButton
					aria-label="open drawer"
					onClick={handleDrawerOpen}
					>
						<MenuIcon />
					</IconButton>
					</Tooltip>
					) 
			}
			</div>
			<Divider />
			<List>
				<ListItem selected={ view=="systems" && ! currentSystem }
					button 
					onClick={ () =>{ 
						setCurrentSystemUID(""); 
						history.push("/app/"); 
						changeView("systems");
						}} >
					<ListItemIcon>
						<HomeWork />
						</ListItemIcon>
					<ListItemText primary="Systems" />
				</ListItem>
			</List>
			{ currentSystem != undefined && 
				<>
					<Divider />
					<List>
						{ mainListItems() }
					</List>
				</>
			}
			<Divider />
			<List>{secondaryListItems() }</List>
			</>
		);
	}

	const mainListItems = () =>{ 
		return(
		<div>
		 
		  { 
		  	currentSystem != undefined && view != "admin" && view != "agent" && view != "account" && 
			  <>
			<ListItem button selected={ view == "scenes" || (view == "systems" && currentSystem)} onClick={ () =>{changeView("scenes")}}>
			  <Tooltip title="Scenes">
			  <ListItemIcon>
				<Palette />
			  </ListItemIcon>
			  </Tooltip>
			  <ListItemText primary="Scenes" />
			</ListItem>
			<ListItem selected={view == "schedules"} button onClick={ () =>{changeView("schedules")}}>
			<Tooltip title="Schedules">
			  <ListItemIcon>
				<CalendarToday />
			  </ListItemIcon>
			  </Tooltip>
			  <ListItemText primary="Schedules" />
			</ListItem>
			{ /*
			<>
			<ListItem selected={view == "layers"} button onClick={ () =>{changeView("layers")}}>
			  <ListItemIcon>
				<LayersIcon />
			  </ListItemIcon>
			  <ListItemText primary="Layers" />
			</ListItem> 
			<ListItem selected={view == "routines"} button onClick={ () =>{changeView("routines")}}>
			<ListItemIcon>
			  <AvTimerIcon />
			</ListItemIcon>
			<ListItemText primary="Routines" />
		  </ListItem> 
			</>
			*/
			}
			{ !guest && 
				<ListItem selected={view == "users"} button onClick={ () =>{changeView("users")}}>
				<Tooltip title="Users">
				<ListItemIcon>
				  <PeopleIcon />
				</ListItemIcon>
				</Tooltip>
				<ListItemText primary="Users" />
			  </ListItem>
			}
			
			<ListItem selected={view == "gateways"} button onClick={ () =>{changeView("gateways")}}>
			<Tooltip title="Gateways">
			  <ListItemIcon>
				<Router />
			  </ListItemIcon>
			  </Tooltip>
			  <ListItemText primary="Gateways" />
			</ListItem>
			</>
		  }
		  
		</div>
	  );
	}
	const secondaryListItems = () => {
		return(
		<div>
		  { userAdmin && false &&
			<ListItem selected={view == "admin"}  button onClick={ () =>{changeView("admin")}}>
				<ListItemIcon>
				<Settings />
				</ListItemIcon>
				<ListItemText primary="Administrator" />
			</ListItem>
			}
			{ userAgent && false &&
				 <ListItem selected={view == "agent"} button onClick={ () =>{changeView("agent")}}>
				 <ListItemIcon>
				   <AssignmentIcon />
				 </ListItemIcon>
				 <ListItemText primary="Agent" />
			   </ListItem>
			}
		 	<ListItem selected={view == "account"} button onClick={ () =>{changeView("account")}}>
				 <ListItemIcon>
				   <AccountCircle />
				 </ListItemIcon>
				 <ListItemText primary="Account" />
			   </ListItem>
	
		</div>
	  );
	}
	
	return (
	<>
		<div className={classes.root}>
			<TopNav handleOpen={handleDrawerOpen} menuopen={open} cl_in={clsx(classes.appBar, open && classes.appBarShift)} history={history}></TopNav>
			<Hidden smUp>
				<Drawer
					variant="temporary"
					classes={{
						paper: classes.drawerPaper,
					}}
					open={open}
					onClose={handleDrawerClose}
					>
					{drawer()}
				</Drawer>
			</Hidden>
			<Hidden xsDown>
				<Drawer
					variant="permanent"
					classes={{
						paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
					}}
					open={open}
					>
					{ drawer() }
				</Drawer>
			</Hidden>
			<main className={classes.content}>
        		<div className={classes.appBarSpacer} />
				<Container  className={classes.container}>
					{ view == "admin" &&
						<Admin history={history}></Admin>
					}
					{ view == "agent" &&
						<Agent></Agent>
					}
					{
						view == "account" &&
						<Account></Account>
					}
					{ view != "admin" && view != "agent" && view != "account" && 
						<Home view={view}></Home>
					}
					<Box pt={4}>
            			<Copyright /><Version />
          			</Box>
					
				</Container>
			</main>
		</div>
		 
		
	</>
  	);
};

export default Main;