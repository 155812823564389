import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ConfirmDialog({open, message, title, yes_text, no_text, on_yes, on_no}) {
	
	const handleClose = ( is_yes ) => {
		if(is_yes){ 
			on_yes();
		} else {
			on_no();
		}
	};
  
	return (
	  <div>
		
		<Dialog
		  open={open}
		  onClose={handleClose}
		  aria-labelledby="alert-dialog-title"
		  aria-describedby="alert-dialog-description"
		>
		  <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
		  <DialogContent>
			<DialogContentText id="alert-dialog-description">
				{ message	}
			</DialogContentText>
		  </DialogContent>
		  <DialogActions>
			<Button onClick={ () => handleClose(false) } color="secondary">
				{no_text}	
			</Button>
			<Button onClick={() => handleClose(true)} color="secondary" autoFocus>
			  	{yes_text}
			</Button>
		  </DialogActions>
		</Dialog>
	  </div>
	);
  }