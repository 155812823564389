import React, { useCallback, useState, useEffect } from "react";
import { withRouter, Redirect } from "react-router";
import firebase from "./firebase";
import { useAuth } from "./Auth";
import TopBanner from './TopBanner'

import { TextField, Button, Grid, Container, Paper, Typography, Link } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Footer from './Footer'

const useStyles = makeStyles(theme => ({
	
	main: {
		height: '60vh',
	},
	textField: {
	  
		width: 250,
	},
	paper: {
		padding: theme.spacing(3, 2),
	},
	form: {
		padding: theme.spacing(3, 2),
		width: '100%',
	}
  }));


const SignInForm = ({ history, icon }) => {
	const classes = useStyles();
	const [ values, setValues] = useState({})

  	const logIn = async (event) => {
		event.preventDefault();
		const { email, password } = values ;
		try {
			await firebase
			.auth()
			.signInWithEmailAndPassword(email, password);
			history.push("/app");
		} catch (error) {
			alert(error);
		}
	}

	const currentUser = useAuth();

	if (currentUser) {
		return <Redirect to="/app" />;
		//       shistory.push("/app");
	}
	const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };

	return (
		<div >
			
			<Grid className={ classes.main} container 
						justify="center"
						alignItems="center"
						>

				<Grid item>
					<Paper className={classes.paper}>
						
							<form>
							<Grid className={classes.form} container direction="column"
									justify="center"
									alignItems="center"
									spacing={1}>
								<Grid item>
									<TextField
										id="email"
										label="Email"
										value={values.email}
										className={classes.textField}
										onChange={handleChange('email')}
										autoComplete="email"
										margin="normal"
									/>
								</Grid>
								<Grid item>
									<TextField
										id="password"
										label="Password"
										type="password"
										className={classes.textField}
										value={values.password}
										onChange={handleChange('password')}
										autoComplete="current-password"
										margin="normal"
									/>
								</Grid>
								
								<Grid item>
									<Button className={ classes.textField } variant="contained" onClick={logIn}>Log in</Button>
								</Grid>
								
							</Grid>
							</form>
						<Typography m={3}>Don't have an account? <Link href="/signup">Sign Up</Link></Typography>
					</Paper>
				</Grid>
			</Grid>
			
		<Footer></Footer>
		</div>
	);
};

export default withRouter(SignInForm);