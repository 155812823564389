import React,  { useState, useEffect } from 'react';
import { Grid, Paper,Divider, Container, Fab, Tooltip, Box, Card, CardContent, CardActions, ToolTip, IconButton, SvgIcon, Typography,
		ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Dialog } from '@material-ui/core';
import { Edit, Delete, PlayArrow, HomeWork, MoreVert, ExpandMore} from '@material-ui/icons';
import {palette} from '@material-ui/system';
import { Add } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from "./common/Auth";
import SceneCard from './SceneCard';
import GatewayCard from './GatewayCard';
import SVGColorBlock from './SVGColorBlock';
import GatewayEditor from './GatewayEditor';
import ScheduleEditor from './ScheduleEditor';
import ScheduleCard from './ScheduleCard';
import ScheduleView from './ScheduleView';
import ColorEditor from './ColorEditor';
import Calendar from './Calendar';
import RulesView from './RulesView';

import { useGateways, useScenes, useCurrentSystem, useSchedules} from './context/SystemContext';
import {firestore, FieldValue} from './common/firebase'
import _default from '@material-ui/pickers/views/Calendar/Calendar';
import GuestList from './GuestList';

const useStyles = makeStyles(theme => ({
	
	addBox: {
	  minHeight: 140,
	  marginTop: 20,
	  marginBottom: 20
	},
	actionCard: {
		justifyContent: 'center'
	},
	
  }));

const convertColor = (color) =>{
	color.r = color.r/255 * 100;
	color.g = color.g/255 * 100;
	color.b = color.b/255 * 100;
	return color;

}

function SystemView( {view} ){
	let id = undefined;
	
	const { currentSystem } = useCurrentSystem();
	const currentUser = useAuth();
	const { scenes } = useScenes(currentSystem.uid, 100);
	const { gateways } = useGateways(currentSystem.uid, 100);
	const { schedules } = useSchedules(currentSystem.uid, 100);
	

	const [ gwToEdit, setGwToEdit ] = useState(undefined);
	const [ creatingGateway, setCreatingGateway ] = useState(false);
	const [ showCurrentColors, setShowCurrentColors ] = useState(false);
	const [ showScenes, setShowScenes ] = useState(true);
	const [ showSchedules, setShowSchedules ] = useState(true);
	const [ scheduleToEdit, setScheduleToEdit ] = useState(undefined);

	const [ colorToEdit, setColorToEdit ] = useState(undefined);

	const [guest, setGuest ] = useState(false);

	useEffect ( () =>{ 
		setGuest( currentUser.uid != currentSystem.userId );
	});
	
	const classes = useStyles();
	

	const newScene = async() => {

		const colors = [ ];
		for( var i = 0; i<currentSystem.num_colors; i++){
			colors.push( { 	r: Math.floor( (Math.random() * 100) + 1 ),
							g: Math.floor( (Math.random() * 100) + 1 ), 
							b: Math.floor( (Math.random() * 100) + 1 )
						});
		}
		const dmx = [];
		firestore.collection("scenes").add({
			name: "Scene " + (scenes.length + 1),
			createdAt: FieldValue.serverTimestamp(),
			colors: colors,
			system_id: currentSystem.uid,
			dmx: dmx
		  });
	}
	const newSchedule = async() =>{
		const schedule = { system: currentSystem.uid };
		setScheduleToEdit(schedule);
	}
	
	const newGateway = async() => {
		const gateway ={ name: "New Gateway", serial: "" };
		setCreatingGateway(true);
		setGwToEdit(gateway);

	}

	const doActivateScene = async(scene) => {
		firestore.doc("systems/"+currentSystem.uid )
				.update( {color: scene.colors, current_scene: scene.uid, current_scen_name: scene.name } );
	}
	
	const doDeleteScene = async(scene) =>{
		firestore.doc('scenes/'+ scene.uid).delete();
	}

	const doEditGateway = (gateway) => {
		setGwToEdit(gateway);
	}
	
	const doDeleteGateway = ( gateway ) =>{
		firestore.collection('gateways').doc(gateway.device_id).delete();
	}
	
	const endEditGateway = () => {
		setCreatingGateway(false);
		setGwToEdit(undefined);
	}

	const endEditSchedule = () => {
		setScheduleToEdit(undefined);
	}

	const doDeleteSchedule = (schedule) => {
		firestore.doc('schedules/' + schedule.uid).delete();
	}

	const editColor = (index) => {
		// pop up a color name editor
		
		setColorToEdit(index);
	} 

	const setColorOpen = (open) =>{
		if( !open){
			setColorToEdit(undefined);
		}
	}
	const saveColor = ( color ) =>{
		var color_names;
		if(currentSystem.color_names == undefined){
			color_names = []; 
		}else {
			color_names = [ ...currentSystem.color_names];

		}
		if(color.index != undefined){
			while(color_names.length < color.index){
				color_names.push("");
			}
			color_names[color.index] = color.name;
		}
		firestore.doc("systems/"+currentSystem.uid )
				.update( { color_names: color_names } );
	}
	const getColorName = (index)=>{
		if( currentSystem.color_names != undefined && currentSystem.color_names.length > index){
			return currentSystem.color_names[index];
		} else {
			return "Color " + (index + 1);
		}
	}
	const generateEditColor = () =>{
		if( colorToEdit != undefined){
			let ret_val = { index: colorToEdit };
			if( currentSystem.color_names != undefined && currentSystem.color_names.length > colorToEdit){
				ret_val.name = currentSystem.color_names[colorToEdit];
			} else {
				ret_val.name = "Color " + (colorToEdit + 1);
			}
			return ret_val;
		} else {
			return {};
		}
		
	}
	const createCurrentColors = () => {
		let current_colors = [];
		let num_colors = 1;
		if( currentSystem.num_colors != undefined){
			num_colors = currentSystem.num_colors;
		}
		for( let i = 0; i < num_colors; i++){
			let color = { r: 0, g:0, b:0 };
			if(currentSystem.color && currentSystem.color.length > i){
				color = currentSystem.color[i];
			}
			current_colors.push(
						<Grid item key={i}>
							<Card>
								<h3>{getColorName(i)}</h3>
								<CardContent>
								<SVGColorBlock single fill={'red'} data={ [color]} size={120} />
								</CardContent>
								<CardActions className={classes.actionCard}>
									<Tooltip title="Edit Name">
									<IconButton onClick={ () => editColor(i) } >
										<Edit />
									</IconButton>
								</Tooltip>
								</CardActions>
							</Card>
						</Grid> );
		}
		return current_colors;
	}
	const createCurrentColorsMini = () => {
		let current_colors = [];
		let num_colors = 1;
		if( currentSystem.num_colors != undefined){
			num_colors = currentSystem.num_colors;
		}
		for( let i = 0; i < num_colors; i++){
			let color = { r: 0, g:0, b:0 };
			if(currentSystem.color && currentSystem.color.length > i){
				color = currentSystem.color[i];
			}
			current_colors.push(
				<Grid item>

					<Typography>{getColorName(i)}</Typography>
					<SVGColorBlock single fill={'red'} data={ [color]} size={40} />	

						
				</Grid>
						
				
								);
		}
		return current_colors;
	}
	const toggleCurrentColors = (event) => {
		setShowCurrentColors( !showCurrentColors);
	}
	const toggleScenes = (event) => {
		setShowScenes( !showScenes);
	}

	const toggleSchedules = ( event ) => {
		setShowSchedules(!showSchedules);
	}
	const doActivateBlackout = () =>
	{
		var colors = [];
		for( var i = 0; i < currentSystem.num_colors; i++){
			colors.push({ r: 0, g: 0, b:0 });
		}
		firestore.doc("systems/"+currentSystem.uid )
				.update( {color: colors, current_scene: 0, current_scen_name: "blackout" } );
	}
	
	return ( 
		
		<>
			{   currentSystem &&
					
					
				<Paper>
					<Box alignItems="center" display='flex'>

					
					<Box flexGrow={1}><h1>{currentSystem.name ? currentSystem.name : currentSystem.uid}</h1></Box>		
					<Box>
						<IconButton disabled={guest}>
							<Edit /> 
						</IconButton>
					</Box>
					</Box>
					<ExpansionPanel style={{ background: 'transparent'}} expanded={showCurrentColors} onChange={toggleCurrentColors}>
							<ExpansionPanelSummary
									expandIcon={<ExpandMore />}
								aria-controls="currentcolor-content"
								id="currentcolor-header">
								<Container>
									<h1 >{ <SVGColorBlock  size={24} fill={'gray'} data={currentSystem.color} />} Current Colors </h1>
									{ !showCurrentColors && <div>
										<Grid padding={2} spacing={2} container
											direction="row"
											justify="space-evenly"
											alignItems="center">{ createCurrentColorsMini() }</Grid></div> }
								</Container>
							</ExpansionPanelSummary>
					
					
						<ExpansionPanelDetails>
							<Grid 
								container 
								direction="row"
								justify="space-evenly"
								alignItems="center" 
								spacing={2}
								padding={2}>
							{ createCurrentColors() } 

							</Grid>
							
						</ExpansionPanelDetails>
					</ExpansionPanel>
				</Paper>
					 
			}
			
			{ (view == "scenes" || view == "systems") && 
			
				<>
					<h1>Scenes</h1>
					<Grid 
						container 
						direction="row"
						justify="space-evenly"
						alignItems="center" 
						spacing={2}
						padding={2}>
						
							
							<Grid item key={0}>
									<SceneCard  guest={guest}
												key={0} 
												active={ currentSystem && currentSystem.current_scene == 0 } 
												scene={undefined} 
												activateScene={doActivateBlackout}
												></SceneCard>
								</Grid>
						{
								scenes && scenes.map( (scene, index) => (
								<Grid item key={scene.uid}>
									<SceneCard  guest={guest}
												key={scene.uid} 
												active={ currentSystem && currentSystem.current_scene == scene.uid } 
												scene={scene} 
												activateScene={doActivateScene}
												deleteScene={doDeleteScene}></SceneCard>
								</Grid>
							))
						}
						</Grid>
					<Box m={2}>
						<Tooltip title="Add Scene">
						<Fab variant='round' color="secondary" onClick={newScene}>
							<Add />
						</Fab>
						</Tooltip>
					</Box>
				</>
			
			}
			
			{
				view == "schedules" &&
			

						<>
						<h1>Schedules</h1>
						{ /* <ScheduleView></ScheduleView> */	}
						<div style={{flexgrow: 1}}>
						<Grid
							style={{ flexgrow: 1 }}
							container
							justify="center"
							alignItems="space-evenly" 
							spacing={4}
							padding={4} 
							direction="column">
							<Grid item>
								
								{ 
								// <Calendar /> 
								}
				
							</Grid>
							<Grid item>
								<Grid
									container
									justify="center"
									padding={4}
									spacing={4}
									alignItems="space-around" 
									
								>
									{
										schedules && schedules.map((schedule, index) => (
											<Grid item xs={9} sm={6} lg={3} >
												<ScheduleCard key={index} guest={guest} schedule={schedule} deleteSchedule={doDeleteSchedule} scenes={scenes}></ScheduleCard>
											</Grid>
										))
									}

								</Grid>
								<Box m={2}>
									<Tooltip title="Add Schedule">
										<Fab variant='round' color="secondary" onClick={newSchedule}>
											<Add />
										</Fab>
									</Tooltip>
								</Box>
								
							</Grid>
						</Grid>


						{
							scheduleToEdit && <ScheduleEditor open={scheduleToEdit!=undefined} scenes={scenes} doneEdit={endEditSchedule}></ScheduleEditor>
						}
						</div>
					</>
			
			}
			{ view == "layers" && 
				<>
				<h1>Layers</h1>
				<Calendar></Calendar>
				</>
			}
			{ view == "routines" && <RulesView></RulesView>}
			{ view == "users" && !guest  && <GuestList></GuestList>}
			{ view == "gateways" &&
			
			
				<>
				
					<h1>Gateways</h1>
					<Grid 
						container 
						direction="row"
						justify="space-evenly"
						alignItems="center" 
						spacing={2}>
					{
						gateways && gateways.map( (gateway, index) => (
							<Grid item key={gateway.device_id}>
								<GatewayCard guest={guest} 
											gateway={gateway} 
											editGateway={doEditGateway}
											deleteGateway={doDeleteGateway}></GatewayCard>
							</Grid>
						))
					}
					</Grid>
					
					<Box m={2}>
						<Tooltip title="Add Gateway">
							<Fab disabled={guest} variant='round' color="secondary" onClick={newGateway}>
								<Add />
							</Fab>
						</Tooltip>
					</Box>
					{
					gwToEdit && 
					<GatewayEditor    open={gwToEdit != undefined} 
										gateway={gwToEdit} 
										editGateway={doEditGateway} 
										newgateway={creatingGateway} 
										endEdit={endEditGateway} title={creatingGateway ? "New Gateway" : "Edit Gateway"}></GatewayEditor>
		
					}
				
				
				</>
		}

		
		<ColorEditor open={colorToEdit != undefined} color={ generateEditColor()  } setOpen={setColorOpen} saveColor={saveColor}></ColorEditor>	
		
		</>
		);
}


export default SystemView;