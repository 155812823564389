import React , { useState, useEffect } from 'react';

import {Card, CardHeader, CardContent, CardActions, Divider, Tooltip, IconButton, CardActionArea, Avatar, Menu, MenuItem } from '@material-ui/core';
import { Edit, Delete, PlayArrow, Check, Palette, MoreVert, Lock} from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';

import SVGColorBlock from './SVGColorBlock';
import SceneEditor from './SceneEditor';
import ConfirmDialog from './ConfirmDialog';

import { useCurrentSystem } from './context/SystemContext';
import { firestore } from './common/firebase';

const useStyles = makeStyles(theme => ({
	

	addBox: {
	  minHeight: 140,
	  marginTop: 20,
	  marginBottom: 20
	},
	actionCard: {
		justifyContent: 'center'
	},
	activeScene: {
		color: 'green',
	},
	inactiveScene: {
		color: 'transparent',
	},
	avatar: {
		backgroundColor : '#ded',
	  }
  
  }));



function SceneCard ({ scene, active, activateScene, deleteScene, guest}){
	
	const classes = useStyles();
	const {currentSystem} = useCurrentSystem();

	const [editing, setEditing ] = useState(false);
	const [ delete_confirm, setDeleteConfirm ] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);

	const editScene = (event) => {
		setEditing(true);
	}

	const doDeleteScene = (event) => {
		setDeleteConfirm(true);
	}

	const doActivateScene = (event) => {
		activateScene(scene);
	}

	const doneEdit = (event) => {
		setEditing(false);
	}

	const deleteYes = async () =>{
		await deleteScene(scene);
		setDeleteConfirm(false);
	}

	const deleteNo = () =>{
		setDeleteConfirm(false);
	}

	const openMenu = (e) => {
		
		setAnchorEl(e.currentTarget);
	}
	const closeMenu = () => {
		setAnchorEl(null);
	}
	const lockScene = () => {
		let locked = !scene.locked;
		firestore.collection('scenes').doc(scene.uid).update({locked: locked });
		closeMenu();
	}
	return (
		<div>
		<Card  style={{width:'15rem'}}>
			
				<CardHeader action={ active ? 
											<IconButton disabled>{scene && scene.locked  ? <Lock></Lock> : null }<Check className={classes.activeScene}/></IconButton> 
											: <IconButton disabled>{scene && scene.locked  ? <Lock></Lock>: null }<Check className={classes.inactiveScene} /> </IconButton> } 
						title={scene ? scene.name : "Blackout"}
						avatar={ <Avatar className={classes.avatar}><Palette /></Avatar>}> 
				</CardHeader>
				<Divider></Divider>
				<Tooltip title="Activate Scene">
				<CardActionArea disabled >
					<CardContent>
						<SVGColorBlock size={100} data={scene ? scene.colors : [ {r: 0, g:0, b:0 }]} fill='blue'></SVGColorBlock>
					</CardContent>
				</CardActionArea>
			</Tooltip>
			<Divider></Divider>
			<CardActions className={classes.actionCard}>
				{ scene && 
				<Tooltip title="Edit Scene">

					<IconButton disabled={scene.locked} onClick={ editScene}>
						<Edit />
					</IconButton>
				</Tooltip>
				}
				
				<Tooltip title="Activate Scene">
					<IconButton onClick={ doActivateScene }>
						<PlayArrow />
					</IconButton>
				</Tooltip>
				{ scene && 
				<IconButton aria-label="more" aria-haspopup="true" onClick={openMenu}>
						<MoreVert />
					</IconButton>
				}
			</CardActions>
		</Card>
		{ editing &&
			<SceneEditor open={editing} scene={scene} doneEdit={doneEdit} numColors={currentSystem.num_colors} colorNames={currentSystem.color_names} ></SceneEditor>
		}
		{ deleteScene &&
		<ConfirmDialog 
			open={delete_confirm} message={"Do you really want to delete scene " + scene.name + "?"} 
			title={"Delete Scene?"}
			yes_text={"Delete"}
			no_text={"Cancel"}
			on_yes={deleteYes} 
			on_no={deleteNo}></ConfirmDialog>
		}
		{ scene  && 
		<Menu
			id="scene-menu" 
			anchorEl={anchorEl}
			keepMounted
			open={Boolean(anchorEl)}
			onClose={closeMenu}
			
			>
				
		<MenuItem disabled={guest} onClick={lockScene}>{ scene.locked ? "Unlock Scene" : "Lock Scene" } </MenuItem>
		{ deleteScene && 
			<MenuItem disabled={scene.locked } onClick={doDeleteScene}>Delete Scene</MenuItem>
		}
		</Menu>
		}
		</div>
	);
}

export default SceneCard;