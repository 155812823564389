import React, { useState, useEffect } from 'react';

import { Button, Table, TableContainer, TableRow, TableCell, Typography, Paper, TableHead} from '@material-ui/core';

import { useCurrentSystem } from './context/SystemContext';

import { firestore } from './common/firebase';
import InviteForm from './InviteForm';

const GuestList = () => {
	const [ userList, setUserList ] = useState({});
	const [ inviteOpen, setInviteOpen ] = useState(false);
	const { currentSystem } = useCurrentSystem();
	
	const inviteUser = () => {
		setInviteOpen(true);
	}
	const revokeUser = async(user) => {
		let index = currentSystem.guests.indexOf(user);
		if(index >= 0 && index < currentSystem.guests.length){
			let new_guests = [...currentSystem.guests];
			new_guests.splice(index, 1);
			await firestore.collection('systems')
							.doc(currentSystem.uid)
							.update({ guests: new_guests } );
		}

	}
	
	const getUserDetails = async (user) =>{
		firestore.collection('users')
			.doc(user)
			.get()
			.then( (doc) =>{ 
				if(doc.exists){
					let ul = {...userList};
					var d = doc.data();
					ul[user] = { first: d.first, last: d.last, email: d.email };
					setUserList(ul);  
				}
			} )
			.catch(err =>{});
	}

	useEffect( () => {
		if(currentSystem.guests !== undefined){
			currentSystem.guests.map( user =>
				getUserDetails(user)
			);
		}
	}, [currentSystem.guests]);
	
	return(
		<div>
			<h1>Users</h1>
			<TableContainer component={Paper} >
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>First Name</TableCell>
							<TableCell>Last Name</TableCell>
							<TableCell>Email Address</TableCell>
							<TableCell><Button onClick={inviteUser} variant='outlined'>Invite User</Button></TableCell>
						</TableRow>
					</TableHead>
					{ Object.keys(userList).map( (key , index) =>
						
						<TableRow key={key}>
							<TableCell>{userList[key].first}</TableCell>
							<TableCell>{userList[key].last}</TableCell>
							<TableCell>{userList[key].email}</TableCell>
							<TableCell>
								<Button onClick={ ()=>{ revokeUser(key);}}variant='outlined'>Revoke User</Button>
							</TableCell>
						</TableRow>
					) }
				</Table>
			</TableContainer>
			<InviteForm system={currentSystem} open={inviteOpen} setOpen={ setInviteOpen } title={"Send Invitation"}></InviteForm>
		</div>

	);

}
export default GuestList;