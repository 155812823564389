import React, { useEffect, useState } from 'react';
import { CardActionArea, Card, CardContent, CardHeader, CardActions,Divider, 
	IconButton, Tooltip, Icon, Menu, MenuItem, Button,
	Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from '@material-ui/core';


import { Edit, Delete, PlayArrow, HomeWork, MoreVert, MoreHoriz} from '@material-ui/icons';
import SVGColorBlock from './SVGColorBlock';
import { useCurrentSystem } from './context/SystemContext';
import { makeStyles } from '@material-ui/core/styles';
import InviteForm from './InviteForm';

const useStyles = makeStyles(theme => ({
    
  addBox: {
    minHeight: 140,
    marginTop: 20,
    marginBottom: 20
  },
  actionCard: {
      justifyContent: 'center'
  },
  avatar:{
  },
  cardAction: {
	justifyContent: 'center',
    display: 'block',
    textAlign: 'center'
  },
  sceneCard:{
    minWidth: "18rem",
  },
  systemCardHeader:{
	justifyContent: 'center', 
  }
}));

function SystemCard({system, editSystem, deleteSystem, forgetSystem, guest}){
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [ inviteOpen, setInviteOpen ] = React.useState(false);

  const { setCurrentSystemUID } = useCurrentSystem();

  const edited = () => {

	editSystem(system);
	closeMenu();
  }

  const onDelete = () => {
	  if(guest){
		forgetSystem(system);
	  } else {
		  deleteSystem(system);
	  }
	  closeMenu();
	  setConfirmOpen(false);
  }

  const deleteSys = () => {
	  closeMenu();
	  setConfirmOpen(true);
  }

  const selected = (e) => {
    e.preventDefault();
    setCurrentSystemUID(system.uid);
  }

  const invited = (e) =>{

  }
  const openMenu = (e) =>{
	setAnchorEl(e.currentTarget);
  }

  const closeMenu = () => {
	setAnchorEl(null);
  }

  const handleConfirmClose = () => { 
	  setConfirmOpen(false);
  }

  

  return(
    <div>
      <Card className={classes.sceneCard}>
				<CardHeader className={classes.systemCardHeader}
					
					title={ system.name ? system.name : "Not Named"}
					subheader={system.location ? system.location : "UID " + system.uid }
					avatar={ <Tooltip title={"Select " + system.name}><IconButton onClick={ selected } aria-label="system"><HomeWork /></IconButton></Tooltip> }
					action={ <IconButton disabled><MoreHoriz></MoreHoriz></IconButton>}
				>
				</CardHeader>
				<Divider></Divider>
			<Tooltip title={"Select " + system.name}>
				<CardActionArea onClick={ selected } className={classes.cardAction}>
					<CardContent>
					<SVGColorBlock size={100} fill={'red'} data={ system.color } single={false}></SVGColorBlock>
					</CardContent>
					<Divider></Divider>
				</CardActionArea>
			</Tooltip>
        <CardActions className={classes.actionCard}>
        <Tooltip  title="Edit System Settings">
              <IconButton  onClick={edited}>
                  <Edit />
              </IconButton>
          </Tooltip>
          <Tooltip title={"Select " + system.name}>
              <IconButton onClick={ selected }>
                  <PlayArrow />
              </IconButton>
          </Tooltip>
		  <IconButton aria-label="more" aria-haspopup="true" onClick={openMenu}>
						<MoreVert />
					</IconButton>
        </CardActions>
      </Card>

	  <Menu
			id="system-menu" 
			anchorEl={anchorEl}
			keepMounted
			open={Boolean(anchorEl)}
			onClose={closeMenu}
			
			>
		<MenuItem onClick={edited}>Edit System Settings</MenuItem>
		<MenuItem disabled={guest} onClick={() => { closeMenu(); setInviteOpen(true);}}>Invite a user</MenuItem>
  		<MenuItem  onClick={deleteSys}>{ guest ? "Forget System" : "Delete System"}</MenuItem>
		
		</Menu>
		<Dialog
        open={confirmOpen}
        onClose={handleConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{ guest ? "Forget this system?": "Delete this system?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            { "Do you really want to " + (guest ? "forget " :"delete ")  + ( system.name ? system.name : "Not Named ( UID" + system.uid + ")") + " ?" }
			{ guest && <Typography bold>This cannot be undone, and a new invitation is required to use this system again.</Typography>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="secondary">
            No
          </Button>
          <Button onClick={onDelete} color="secondary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

	  <InviteForm system={system} open={inviteOpen} setOpen={ setInviteOpen } title={"Send Invitation"}></InviteForm>
    </div>
    );
  
  }

  export default SystemCard;