import React, {useState, useEffect} from 'react';

import { useGateways, useScenes, useCurrentSystem, useSchedules} from './context/SystemContext';
import { useAuth } from "./common/Auth";

import RuleEditor from './RuleEditor';
import { Button } from '@material-ui/core';
import { NewReleasesOutlined } from '@material-ui/icons';

import {RRule} from 'rrule';
export default function RulesView({}){
	
	const { currentSystem } = useCurrentSystem();
	const currentUser = useAuth();
	const { schedules } = useSchedules(currentSystem.uid, 100);
	
	const [ editingRule, setEditingRule ] = useState(false);

	const newRule = () =>{
		setEditingRule(true);
	}
	const saveRule = (rule_string) => {
		setEditingRule(false);
	}
	return(
		<div>
			<h1>Rules</h1>
			<Button onClick={newRule}>Add Rule</Button>
			{
				editingRule && <RuleEditor rule_string="" save={ saveRule }></RuleEditor>
			}
		</div>
	);
}