import React, { useEffect, useState } from 'react';
import { Box, Typography,
	TextField, 
	Button, 
	Dialog, 
	DialogContent, 
	DialogTitle,
	DialogContentText, 
	DialogActions, 
	Slider, 
	Divider, 
	Grid,
	Input } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { firestore } from './common/firebase';
import SVGColorBlock from './SVGColorBlock';

function ColorEditor(props){
	
	const clr = props.color;
	const [ color, setColor] = useState({r:100, g:100, b:100});
	
	useEffect( () => {
		
		setColor(clr)
	}, [clr] );

	const handleRed = (event, newValue) => {
		var c = {  ...color, r: newValue};
		props.colorChange(props.id, c);
	  };
	  const handleGreen = (event, newValue) => {
		var c = { ...color, g: newValue};
		props.colorChange(props.id, c);
	  };
	  const handleBlue = (event, newValue) => {
		var c = { ...color,  b: newValue};
		props.colorChange(props.id, c);
	  };

	  const handleR = (event) =>{
		var c = {  ...color, r: Number(event.target.value)};
		props.colorChange(props.id, c);
	  }
	  const handleG = (event) =>{
		var c = {  ...color, g: Number(event.target.value)};
		props.colorChange(props.id, c);
	  }
	  const handleB = (event) =>{
		var c = {  ...color, b: Number(event.target.value)};
		props.colorChange(props.id, c);
	  }
	
	return(
			<Box flexDirection="column" mt={1} border={1} padding={2} borderRadius={5} borderColor={"grey.400"} >
				<Typography>{ props.name}</Typography>
				<Divider></Divider>
				<br></br>
				<SVGColorBlock size={50} data={[color]} fill="black" single={false}></SVGColorBlock>
			
			
				<Box flexDirection="column" >
					<Typography id="label-r" gutterBottom>
						Red
					</Typography>
					<Grid container>
						<Grid item xs>
							<Slider  color="secondary" onChange={handleRed} max={255} value={color.r} valueLabelDisplay={"auto"} aria-labelledby="label-r"></Slider>
						</Grid>
						<Grid item>
							<Input
								
								value={color.r}
								margin="dense"
								onChange={handleR}
								//onBlur={handleBlur}
								inputProps={{
								step: 1,
								min: 0,
								max: 255,
								type: 'number',
								'aria-labelledby': 'label-r',
								}}
							/>
							
						</Grid>
					</Grid>
					<Typography id="label-g" gutterBottom>
						Green
					</Typography>
					<Grid container>
						<Grid item xs>
							<Slider  color="secondary" onChange={handleGreen} max={255} value={color.g} valueLabelDisplay={"auto"} aria-labelledby="label-g"></Slider>
						</Grid>
						<Grid item>
							<Input
								
								value={color.g}
								margin="dense"
								onChange={handleG}
								//onBlur={handleBlur}
								inputProps={{
								step: 1,
								min: 0,
								max: 255,
								type: 'number',
								'aria-labelledby': 'label-g',
								}}
							/>
							
						</Grid>
					</Grid>
					
					<Typography id="label-b" gutterBottom>
						Blue
					</Typography>
					<Grid container>
						<Grid item xs>
							<Slider  color="secondary" onChange={handleBlue} max={255} value={color.b} valueLabelDisplay={"auto"} aria-labelledby="label-b"></Slider>
						</Grid>
						<Grid item>
							<Input
								
								value={color.b}
								margin="dense"
								onChange={handleB}
								//onBlur={handleBlur}
								inputProps={{
								step: 1,
								min: 0,
								max: 255,
								type: 'number',
								'aria-labelledby': 'label-b',
								}}
							/>
							
						</Grid>
					</Grid>
					
				</Box>
	
			</Box>	
			);

}

function SceneEditor({scene, open, doneEdit, numColors, colorNames}){

	const [values, setValues] = React.useState({});
	const [ cols, setCols ] = React.useState([]);
	const theme = useTheme();
  	const matches = useMediaQuery(theme.breakpoints.up('sm'));
	useEffect( () => {
		const { colors, ...rest } = scene;
		setValues(rest);
		setCols( colors);

	}, [scene] );


	const saveScene = async() => {
		const update = { ...values, colors: cols };
		firestore.collection('scenes').doc(scene.uid).update(update);
	}

	const handleChange = name => event => {
		const newVals = { ...values, [name]: event.target.value }
		setValues(newVals);
	};

	const colorChange = (index, color) => {
		var newCols = [...cols];
		newCols[index] = color;
		setCols( newCols );
	}
	
	const handleClose = () =>{
		doneEdit();
	}

	const apply = () => {
		saveScene().then(
			doneEdit()
			);
	};
	const getColorName = (index) =>{
		if(colorNames != undefined && colorNames.length > index){
			return colorNames[index];
		} else {
			return "Color " + (index + 1);
		}
	}
	const generateColorEditors = () =>{
		let editors = [];
		for( let i = 0; i<numColors; i++){
			let color = {r:0, g:0, b:0};
			if( i < cols.length){
				color = cols[i];
			}
			editors.push(<ColorEditor colorChange={colorChange} key={i} id={i} color={color} name={getColorName(i)}></ColorEditor>)
		}
		return editors;
	}
	return(
		<div>
			
			<Dialog fullScreen={!matches} open={open} onClose={handleClose}>
				<DialogTitle>Edit Scene</DialogTitle>
				<DialogContent>
					<form>
					<TextField fullWidth required
						id="standard-name"
						label="Name"
						value={values.name}
						onChange={handleChange('name')}
						margin="normal"
						variant="outlined"
						
					/>
					<div>
					{ generateColorEditors()}
					</div>
					</form>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={handleClose} color="secondary">
						Cancel
					</Button>
					<Button variant="contained" onClick={apply} color="secondary">Save</Button>
				</DialogActions>
			</Dialog>
		</div>
	);

}


export default SceneEditor;