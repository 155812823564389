import React, {
	useEffect,
	useState,
	useContext
} from "react";

import {firestore} from "../common/firebase";



// *** System API ***
/*
system = uid => this.db.doc(`systems/${uid}`);
systems = () => this.db.collection('systems');
usersystems = userid => this.systems().where( "userId", "==", `${userid}`);

gateway = uid => this.db.doc(`gateways/${uid}`);
gatewayconfig = uid => this.db.doc(`gateways/${uid}/config/network`);
gatewaystate = uid => this.db.doc(`gateways/${uid}/config/state`);

gateways = () => this.db.collection('gateways');
systemgateways = sysid => this.gateways().where("system_id", "==", `${sysid}`);

scene = uid => this.db.doc( `scenes/${uid}` );
scenes = () =>this.db.collection('scenes');
systemscenes = sysid =>this.scenes().where("system_id", "==", `${sysid}`);
*/

function useSystems(uid, limit) {
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(true)
	const [systems, setSystems] = useState([])
	
	useEffect(
		() => {
			
			const unsubscribe = firestore
				.collection('systems')
				.where( "userId", "==", uid )
				.orderBy('createdAt', 'desc')
				.limit(limit)
				.onSnapshot(
					snapshot => {
					const sys = [];
					snapshot.forEach(doc =>
							sys.push({...doc.data(), uid: doc.id} ),
						);
						setSystems(sys.reverse());
						setLoading(false);
					} ,
					err => { 
						setError(err);
					}
				)
			return () => unsubscribe()
		},
		[uid, limit]
	)

	return {
		error,
		loading,
		systems,
	};
}

function useGuestSystems(uid, limit){
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(true)
	const [guestSystems, setGuestSystems] = useState([])
	
	useEffect(
		() => {
			
			const unsubscribe = firestore
				.collection('systems')
				.where( "guests", 'array-contains', uid )
				.limit(limit)
				.onSnapshot(
					snapshot => {
						const sys = [];
						snapshot.forEach(doc =>
								sys.push({...doc.data(), uid: doc.id} ),
							);
							setGuestSystems(sys.reverse());
							setLoading(false);
						} ,
					err => { 
						setError(err);
					}
				)
			return () => unsubscribe()
		},
		[uid, limit]
	)

	return {
		error,
		loading,
		guestSystems,
	}; 

}

function useScenes(uid, limit) {
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(true)
	const [scenes, setScenes] = useState([])
	
	useEffect(
		() => {
			
			const unsubscribe = firestore
				.collection('scenes')
				.where( "system_id", "==", uid )
				.orderBy('createdAt', 'desc')
				//.orderBy("name")
				.limit(limit)
				.onSnapshot(
					snapshot => {
					const sys = [];
					snapshot.forEach(doc =>
							sys.push({...doc.data(), uid: doc.id} ),
						);
						setScenes(sys.reverse());
						setLoading(false);
					} ,
					err => { 
						setError(err);
					}
				)
			return () => unsubscribe()
		},
		[uid , limit]
	)

	return {
		error,
		loading,
		scenes,
	};
}



function useGateways(uid, limit) {
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(true)
	const [gateways, setGateways] = useState([])
	
	useEffect(
		() => {
			
			const unsubscribe = firestore
				.collection('gateways')
				.where( "system_id", "==", uid )
				.orderBy('createdAt', 'desc')
				.limit(limit)
				.onSnapshot(
					snapshot => {
					const sys = [];
					snapshot.forEach(doc =>
							sys.push({...doc.data(), uid: doc.id} ),
						);
						setGateways(sys.reverse());
						setLoading(false);
					} ,
					err => { 
						setError(err);
					}
				)
			return () => unsubscribe()
		},
		[uid, limit]
	)

	return {
		error,
		loading,
		gateways,
	};
}

function useSchedules(uid, limit) {
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(true)
	const [schedules, setSchedules] = useState([])
	
	useEffect(
		() => {
			
			const unsubscribe = firestore
				.collection('schedules')
				.where( "system", "==", uid )
				.orderBy('performAt', 'asc')
				.limit(limit)
				.onSnapshot(
					snapshot => {
					const sys = [];
					snapshot.forEach(doc =>
							sys.push({...doc.data(), uid: doc.id} ),
						);
						setSchedules(sys);
						setLoading(false);	
					} ,

					err => { 
						setError(err);
					}
				)
			return () => unsubscribe()
		},
		[uid, limit]
	)

	return {
		error,
		loading,
		schedules,
	};
}


const CurrentSystemContext = React.createContext();

const CurrentSystemProvider = ({ children }) => {
	const [ error, setError] = useState(false);
	const [ currentSystem,  setCurrentSystem ] = useState(undefined);
	const [loading, setLoading] = useState(true);
  	const [ currentSystemUID, setCurrentSystemUID ] = useState("");

  useEffect(
	() =>{
	if( currentSystemUID !== ""){
		const unsubscribe = firestore
			.collection('systems')
			.doc(currentSystemUID)
			.onSnapshot(
				doc => {
					setLoading(false)
					setCurrentSystem({ ...doc.data(), uid: doc.id})
				},
				err => {
					setError(err)
				}
			);
		return () => unsubscribe()
	} else {
		setCurrentSystem(undefined);
	}
},
[ currentSystemUID]);

  return (
	<CurrentSystemContext.Provider value={ { error, loading, currentSystem, setCurrentSystemUID } } >
	  {children}
	</CurrentSystemContext.Provider>
  );
};
// useAuth Hook!
const useCurrentSystem = () => {
  const { error, loading, currentSystem, setCurrentSystemUID } = useContext(CurrentSystemContext);
  return { error, loading, currentSystem, setCurrentSystemUID } ;
}

export { CurrentSystemProvider, CurrentSystemContext, useSystems,  useGuestSystems, useScenes, useGateways, useCurrentSystem, useSchedules };



