import React, { useEffect, useState } from 'react';
import { Calendar, Views, momentLocalizer }  from 'react-big-calendar';
import {useCurrentSystem,useSchedules} from './context/SystemContext';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { FlipRounded } from '@material-ui/icons';
import RuleEditor from './RuleEditor';
const localizer = momentLocalizer(moment) // or globalizeLocalizer


const ScheduleView = () => {
	const { currentSystem } = useCurrentSystem();
	const { schedules } = useSchedules(currentSystem.uid, 999);
	const [ events, setEvents ] = useState([]);
	useEffect( () => {
		let ev = [];
		var last = -1 ;
		schedules.forEach( schedule => {
			var event = {};
			event.title = schedule.name;
			event.start = schedule.performAt.toDate();	
			event.end = event.start
			ev.push(event);
			if(schedule.repeat){
				for(var i = 0; i<100; i++){
					var e = {...event};
					var st = new Date( schedule.performAt.toDate().getTime() + (60000 * schedule.repeat * (i+1))) ;
					e.start = st;
					e.end = st;
					ev.push(e);
				}
			}
			
		});

		setEvents(ev);
    }, [schedules ] );

	return( 
	
	<div  style={{"height": "400px"}}>
		<Calendar
			localizer={localizer}
			events={events}
			startAccessor="start"
			endAccessor="end" 
			defaultView={Views.WEEK}
	  />
	</div>
	);
}

export default ScheduleView;
