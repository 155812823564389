import React from "react";
import TopNav from "./TopNav";
import { useAuth, useUserExt, useAgentExt, useAdminExt} from "./common/Auth";
import {useCollection} from "./context/AdminContext";
import { Grid, List, ListItem, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from "@material-ui/core";
import {firestore} from "./common/firebase";
import { makeStyles } from '@material-ui/core/styles';
const shortid = require('shortid');

const useStyles = makeStyles({
	root: {
		flexGrow : 1,
	},
	table: {
		width: "100%",
	},
	dashboard: {
		flexGrow: 1,
		padding: 2,
		alignContent: "center",
	},
  });
  
const UserListRow = ( { user } ) => {

	const { error: adminError, loading: adminLoading, userAdmin } = useAdminExt(user.uid);
	const { error: agentError, loading: agentLoading, userAgent } = useAgentExt(user.uid);

	const makeAgent = async (e) =>{
		e.preventDefault();
		if(userAgent && userAgent.revoked){
			await firestore.collection("agent").doc(user.uid).update({ revoked: false });
		} else {
			const id = shortid.generate();
			await firestore.collection("agent").doc(user.uid).set({ agentID: id, revoked: false });
		}
	}

	const revokeAgent = async (e) =>{
		e.preventDefault();
		await firestore.collection("agent").doc(user.uid).update({ revoked: true });
		
	}	

	const makeAdmin = async (e) =>{
		e.preventDefault();
		if(userAdmin && userAdmin.revoked){
			await firestore.collection("admin").doc(user.uid).update({ revoked: false });
		} else {
			const id = shortid.generate();
			await firestore.collection("admin").doc(user.uid).set({ agentID: id, revoked: false });
		}
	}
	const revokeAdmin = async (e) =>{
		e.preventDefault();
		await firestore.collection("admin").doc(user.uid).update({ revoked: true });
		
	}	
	return(
		<TableRow key={user.uid}>
			<TableCell component="th" scope="row">
				{user.name}
			</TableCell>
			<TableCell align="right">{user.uid}</TableCell>
			<TableCell align="right">{user.email}</TableCell>
			<TableCell align="right">{user.agentID}</TableCell>
			<TableCell align="right">{user.roles && user.roles.join()}</TableCell>
			<TableCell align="right">{user.subscription_active ? "Active" : "Inactive"}</TableCell>
			<TableCell align="right">
				<Grid container
					direction="row"
					justify="space-evenly"
					alignItems="center" 
					spacing={2}>
					<Grid item>
						{ agentError && (<div>{agentError}</div>)}
						{ agentLoading && (<div>Loading</div>) }
						{ (userAgent && userAgent.agentID && !userAgent.revoked) ? 
							(<Button onClick={ revokeAgent } variant="contained">Revoke Agent</Button>) :
							(<Button onClick={ makeAgent }variant="contained">Make Agent</Button>)
						}
						
					</Grid>
					<Grid item>
						{ adminError && <div>{adminError}</div>}
						{ adminLoading && <div>Loading</div> }
						{ (userAdmin && userAdmin.agentID && !userAdmin.revoked) ? 
							(<Button onClick={ revokeAdmin } variant="contained">Revoke Admin</Button>) :
							(<Button onClick={ makeAdmin }variant="contained">Make Admin</Button>)
						}
						
					</Grid>
				
				</Grid>
			</TableCell>
		</TableRow>);

}
const UserList = () => {
	const { collection } = useCollection("users", 100);
	const classes = useStyles();
	return(
		<div className={classes.root}>
		<h4>Users</h4>
		<TableContainer component={Paper}>
		<Table className={classes.table} size="small" aria-label="simple table">
			<TableHead>
			<TableRow>
				<TableCell>Name</TableCell>
				<TableCell align="right">ID</TableCell>
				<TableCell align="right">Email</TableCell>
				<TableCell align="right">AgentID</TableCell>
				<TableCell align="right">Roles</TableCell>
				<TableCell align="right">Active</TableCell>
				<TableCell align="right">Make Agent</TableCell>
			</TableRow>
			</TableHead>
			<TableBody>
			{collection && collection.map(user => (
				<UserListRow key={user.uid} user={user}></UserListRow>
			))}
			</TableBody>
		</Table>
		</TableContainer>
	</div>
	);
}

const SystemList = () => {
	const { collection } = useCollection("systems", 100);
	const classes = useStyles();

	return (
		<div className={classes.root}>
		<h4>Systems</h4>
		<TableContainer component={Paper}>
		<Table className={classes.table} size="small" aria-label="simple table">
			<TableHead>
			<TableRow>
				<TableCell>Name</TableCell>
				<TableCell align="right">Location</TableCell>
				<TableCell align="right">ID</TableCell>
				<TableCell align="right">User</TableCell>
				<TableCell align="right">Actions</TableCell>
			</TableRow>
			</TableHead>
			<TableBody>
			{collection && collection.map(system => (
				<TableRow key={system.uid}>
				<TableCell component="th" scope="row">
					{system.name}
				</TableCell>
				<TableCell align="right">{system.location}</TableCell>
				<TableCell align="right">{system.uid}</TableCell>
				<TableCell align="right">{system.userId}</TableCell>
				<TableCell align="right"><Button variant="contained">Edit</Button></TableCell>
				</TableRow>
			))}
			</TableBody>
		</Table>
	</TableContainer></div> );
}

const GatewayList = () => {
	const { collection } = useCollection("gateways", 100);
	const classes = useStyles();

	return (
		<div className={classes.root}>
		<h4>Gateways</h4>
		<TableContainer component={Paper}>
			<Table className={classes.table} size="small" aria-label="simple table">
				<TableHead>
				<TableRow>
					<TableCell>Name</TableCell>
		
					<TableCell align="right">ID</TableCell>
					<TableCell align="right">User</TableCell>
					<TableCell align="right">Actions</TableCell>
				</TableRow>
				</TableHead>
				<TableBody>
				{collection && collection.map(system => (
					<TableRow key={system.uid}>
					<TableCell component="th" scope="row">
						{system.name}
					</TableCell>
					<TableCell align="right">{system.uid}</TableCell>
					<TableCell align="right">{system.online ? "Online" : "Offline"}</TableCell>
					<TableCell align="right"><Button variant="contained">Edit</Button></TableCell>
					</TableRow>
				))}
				</TableBody>
			</Table>
		</TableContainer>
		</div> );
}

const Actions = () => {
	const classes = useStyles();
	return( <div className={classes.root}>
		<Grid container>
			<Grid item>
				<Button>Invite User</Button>
			</Grid>
			<Grid item>
				<Button>Invite Agent</Button>
			</Grid>
		</Grid>
		
		
	</div>);
}
const Dashboard = () =>{
	const classes = useStyles();

	return (<div className={classes.dashboard}>
				<h3>Dashboard</h3>
				<Grid 
					container 
					direction="column"
					justify="space-evenly"
					alignItems="center" 
					spacing={2}>
						<Grid item xs={12}>
							<Actions></Actions>
						</Grid>
					<Grid item xs={12}>
						<UserList></UserList>
					</Grid>
					<Grid item xs={12}>
						<SystemList></SystemList>
					</Grid>
					<Grid item xs={12}>
						<GatewayList></GatewayList>
					</Grid>
				</Grid>
			</div>);
}


const Admin = ({history}) => {
	const currentUser = useAuth();
	const userExt = useUserExt(currentUser.uid);

	if( userExt.roles && !userExt.roles.includes("ADMIN")) return (<>NOT AUTHORIZED</>);

	return (<>
				<h1>Admin</h1>
				<Dashboard></Dashboard>
			</>);


}
export default Admin;