import React, {
	useEffect,
	useState,
	useContext
} from "react";

import {firestore} from "../common/firebase";



// *** System API ***
/*
system = uid => this.db.doc(`systems/${uid}`);
systems = () => this.db.collection('systems');
usersystems = userid => this.systems().where( "userId", "==", `${userid}`);

gateway = uid => this.db.doc(`gateways/${uid}`);
gatewayconfig = uid => this.db.doc(`gateways/${uid}/config/network`);
gatewaystate = uid => this.db.doc(`gateways/${uid}/config/state`);

gateways = () => this.db.collection('gateways');
systemgateways = sysid => this.gateways().where("system_id", "==", `${sysid}`);

scene = uid => this.db.doc( `scenes/${uid}` );
scenes = () =>this.db.collection('scenes');
systemscenes = sysid =>this.scenes().where("system_id", "==", `${sysid}`);
*/

function useCollection(name, whereName, whereValue, limit) {
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(true)
	const [collection, setCollection] = useState([])
	
	useEffect(
		() => {
			
			const unsubscribe = firestore
				.collection(name)
				.where( whereName, "==", whereValue)
				.limit(limit)
				.onSnapshot(
					snapshot => {
					const sys = [];
					snapshot.forEach(doc =>
							sys.push({...doc.data(), uid: doc.id} ),
						);
						setCollection(sys.reverse());
						setLoading(false);
					} ,
					err => { 
						setError(err);
					}
				)
			return () => unsubscribe()
		},
		[name, whereName, whereValue,limit]
	)

	return {
		error,
		loading,
		collection,
	};
}

function useAgentUsers( agentID, limit ){
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(true)
	const [users, setUsers] = useState([])
	
	useEffect(
		() => {
			
			const unsubscribe = firestore
				.collection("users")
				.where( "agentID", "==", agentID)
				.limit(limit)
				.onSnapshot(
					snapshot => {
					const sys = [];
					snapshot.forEach(doc =>
							sys.push({...doc.data(), uid: doc.id} ),
						);
						setUsers(sys.reverse());
						setLoading(false);
					} ,
					err => { 
						setError(err);
					}
				)
			return () => unsubscribe()
		},
		[ agentID,limit]
	)

	return {
		error,
		loading,
		users,
	};
}

const AgentCustomerContext = React.createContext();

const AgentCustomerProvider = ({ children }) => {
	const [ error, setError] = useState(false);
	const [ agentCustomer,  setAgentCustomer ] = useState(undefined);
	const [loading, setLoading] = useState(true);
	
  const [ currentAgentCustomerID, setAgentCustomerID ] = useState("");

  useEffect(
	() =>{
	if( currentAgentCustomerID !== ""){
		const unsubscribe = firestore
			.collection('users')
			.doc(currentAgentCustomerID)
			.onSnapshot(
				doc => {
					setLoading(false)
					setAgentCustomer({ ...doc.data(), uid: doc.id})
				},
				err => {
					setError(err)
				}
			);
		return () => unsubscribe()
	} else {
		setAgentCustomer(undefined);
	}
},
[ currentAgentCustomerID]);

  return (
	<AgentCustomerContext.Provider value={ { error, loading, agentCustomer, setAgentCustomerID } } >
	  {children}
	</AgentCustomerContext.Provider>
  );
};
// useAuth Hook!
const useAgentCustomer = () => {
  const { error, loading, agentCustomer, setAgentCustomerID } = useContext(AgentCustomerContext);
  return { error, loading, agentCustomer, setAgentCustomerID } ;
}

export { useAgentUsers, AgentCustomerProvider, useAgentCustomer }
