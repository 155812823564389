
import React from 'react';

function SvgContent({ size, data, single }){
  const colors = [];

  var cols = data  ? data : [];

  for( var i = 0; i< cols.length; i++){
    colors.push( "rgb(" + cols[i].r + ","+ cols[i].g + ","+ cols[i].b +")");
  }

  if(single){
	  return( <React.Fragment><rect key={0} id={0} 
		x={0}
		y={0} 
		width={size} 
		height={size} 
		style={{ fill: (colors.length) ? colors[0] : 'black' }} ></rect></React.Fragment>);
  } else {
	const rects =  colors.map((color, index) => 
		
		<rect key={index} id={index} 
		x={ index ? size * 2/3 : 0 } 
		y={ index ? (size/3) * (index -1) : 0 } 
		width={index ? size / 3 : size } 
		height={ index ? size / 3 : size} 
		style={{ fill: color }} ></rect>
	);
	return(
		<React.Fragment>
		{rects}
		</React.Fragment>
	)
  }
}


function SVGColorBlock({size, fill, data, single }){
    
	var viewBox = [0, 0, size, size].join(' ');
	
    return(
      <svg
          xmlns="http://www.w3.org/svg/2000"
          viewBox={viewBox}
          width={size}
          height={size}>
              <SvgContent size={size} data={data} fill={fill} single={single}></SvgContent>
        </svg>
    );
  }

  export default SVGColorBlock;