import React from 'react'
import { RRule } from 'rrule'
import {makeStyles } from '@material-ui/core/styles'
import {
	Typography,
	Input, RadioGroup, FormControl, FormGroup, FormControlLabel, FormLabel, Checkbox,
	Radio, Select, MenuItem, InputLabel,
	Grid,
	Divider,
	Button,
	Box, 
	Accordion, AccordionSummary, AccordionDetails
	
} from '@material-ui/core';
import {MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker} from '@material-ui/pickers';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment , { relativeTimeRounding } from 'moment';
import MomentUtils from '@date-io/moment';

const useStyles = makeStyles((theme) => ({
	formControl: {
	  margin: theme.spacing(1),
	  
	},
	selectEmpty: {
	  marginTop: theme.spacing(2),
	},
	accordion: {
		marginTop: theme.spacing(2),
	},

  }));



const Wrapper = (props) => {
	return (<form>{props.children}</form>);
}

const getUTCDate = (date) => {
	const d = new Date(date)
	return new Date(
		Date.UTC(
			d.getUTCFullYear(),
			d.getUTCMonth(),
			d.getUTCDate(),
			d.getUTCHours(),
			d.getUTCMinutes(),
			d.getUTCSeconds()
		)
	)
}

const frequencies = [
	{ name: "Year(s)", val: RRule.YEARLY },
	{ name: "Month(s)", val: RRule.MONTHLY },
	{ name: "Week(s)", val: RRule.WEEKLY },
	{ name: "Day(s)", val: RRule.DAILY },
	{ name: "Hour(s)", val: RRule.HOURLY },
	{ name: "Minute(s)", val: RRule.MINUTELY }
];

const days = [ 
	{ name: "Monday", val: 0} , 			
	{ name: "Tuesday", val: 1}, 
	{ name: "Wednesday", val: 2},
	{ name: "Thursday", val: 3},
	{ name: "Friday", val: 4},
	{ name: "Saturday", val: 5},
	{ name: "Sunday", val: 6}

];
const RRuleEditor = ({ value, onChange, numeric }) => {
	const classes = useStyles();
	const [state, setState] = React.useState({
		freq: 1,
		dtstart: '',
		until: '',
		count: 0,
		interval: 1,
		wkst: 0,
		byweekday: [],
		bymonth: []
	})
	const [txt, setTxt] = React.useState("");
	const [str, setStr] = React.useState("");
	const [open, setOpen] = React.useState(false);
	const [runmode, setRunMode] = React.useState(0);
	// Handlers
	const toggleByDay = (e) => {
		const { value } = e.target
		const index = state.byweekday.findIndex(
			(wk) => RRule[wk].weekday === RRule[value].weekday
		)
		if (index === -1) {
			const copy = state.byweekday
			copy.push(value)
			setState({ ...state, byweekday: [...copy] })
		} else {
			const copy = state.byweekday
			copy.splice(index, 1)
			setState({ ...state, byweekday: [...copy] })
		}
	}

	const toggleByMonth = (e) => {
		const { value } = e.target
		const index = state.bymonth.findIndex((mon) => mon === +value)
		if (index === -1) {
			const copy = state.bymonth
			copy.push(+value)
			setState({ ...state, bymonth: [...copy] })
		} else {
			const copy = state.bymonth
			copy.splice(index, 1)
			setState({ ...state, bymonth: [...copy] })
		}
	}
	
	const handleRunMode = (e) => {
		setRunMode(e.target.value);
	}


	React.useEffect(() => {
		console.log(state)
		const rule = { ...state }
		if ( !state.byweekday.length) {
			delete rule.byweekday
		}
		if (!state.bymonth.length) {
			delete rule.bymonth
		}
		if (state.dtstart) {
			rule.dtstart = getUTCDate(state.dtstart)
		} else {
			delete rule.dtstart
		}
		if(state.count != undefined && state.count > 0){
			delete rule.until
			setRunMode(2);
		} else {
			delete rule.count; // count must be 0
			if (state.until) {
				rule.until = getUTCDate(state.until)
				setRunMode(1);
			} else {
				delete rule.until;
				setRunMode(0);
			}
		}
		if(state.byweekday == undefined){
			rule.byweekday = [];
		}
		if ( state.byweekday && state.byweekday.length) {
			rule.byweekday = state.byweekday.map((day) => RRule[day])
		}
		const rruleObj = new RRule(rule);
		if ( state.byweekday && state.byweekday.length) {
			rule.byweekday = state.byweekday.map((day) => { return day;})
		}
		//console.log(rruleObj.toString());
		//console.log(rruleObj.all());
		setTxt(rruleObj.toText());
		setStr(rruleObj.toString());
		if (onChange) {
			onChange({
				state: rule
			})

		}
	}, [state])

	React.useEffect(() => {
		const new_v = { ...state, ...value};
		setState(new_v);
	}, [value])

	const generateFrequencyList = () => {

		let list = [];
		frequencies.map((val) => { list.push(<MenuItem value={val.val}>{val.name}</MenuItem>); });
		return list;
	}
	const generateWeekStart = () => {
		let list = [];
		days.map( (val) => { list.push(<MenuItem value={val.val}>{val.name}</MenuItem>); } );
		return list;
	}

	return (
		<>
		{ 
			!open && 
				<>
				
				
					<Grid border={1} p={1} borderRadius={5} container  flexGrow={1}  direction='column' justify='space-around'>
						<Grid item xs={12}>
							<Typography fullWidth >{"Repeat " + txt}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Divider></Divider>
						</Grid>
						<Grid item xs={12}>
							<Button fullWidth variant='outlined'  onClick={() => setOpen(true) }>{'Edit Repeat Rule'}</Button>
						</Grid>
					</Grid>
					
					
				
				</>
		}
		{ open && 
		<Box borderRadius={5} p={1} border={1} borderColor={"grey.500"}>
		<Wrapper>
			
			<Typography>{"Repeat " + txt}</Typography>
			<Divider />
			
			<FormLabel className={classes.formControl} >Repeat Every</FormLabel>
			<Grid className={classes.formControl} container direction='row' justify='space-evenly'>
				<Grid item>
				<Input variant='outlined'
					type='number'
					name='interval'
					min='1'
					value={state.interval}
					onChange={(e) => setState({ ...state, interval: +e.target.value })}
				/>
				</Grid>
				<Grid item>
					<Select variant='outlined'
					value={state.freq}
					onChange={(e) => setState({ ...state, freq: +e.target.value })}
				>
					{generateFrequencyList()}
				</Select>
				</Grid>
			</Grid>
			
			
			<FormControl className={classes.accordion} variant='outlined' fullWidth component='fieldset'>
				<Accordion>
					<AccordionSummary  expandIcon={<ExpandMoreIcon />}>
						<FormLabel>Only On Days</FormLabel>
					</AccordionSummary>
					<AccordionDetails>
					<FormGroup row>
					<FormControlLabel
						control={<Checkbox
							name='byMonday'
							value='MO'
							checked={state.byweekday && state.byweekday.includes('MO')}
							onChange={toggleByDay}
						/>}
						label='Monday'
					/>
					<FormControlLabel
						control={<Checkbox
							name='byTuesday'
							value='TU'
							checked={state.byweekday && state.byweekday.includes('TU')}
							onChange={toggleByDay}
						/>}
						label='Tuesday'
					/>
					<FormControlLabel
						control={<Checkbox
							name='byWednesday'
							value='WE'
							checked={state.byweekday && state.byweekday.includes('WE')}
							onChange={toggleByDay}
						/>}
						label='Wednesday'
					/>
					<FormControlLabel
						control={<Checkbox
							name='byThursday'
							value='TH'
							checked={state.byweekday && state.byweekday.includes('TH')}
							onChange={toggleByDay}
						/>}
						label='Thursday'
					/>
					<FormControlLabel
						control={<Checkbox
							name='byFriday'
							value='FR'
							checked={state.byweekday && state.byweekday.includes('FR')}
							onChange={toggleByDay}
						/>}
						label='Friday'
					/>
					<FormControlLabel
						control={<Checkbox
							name='bySaturday'
							value='SA'
							checked={state.byweekday && state.byweekday.includes('SA')}
							onChange={toggleByDay}
						/>}
						label='Saturday'
					/>
					<FormControlLabel
						control={<Checkbox
							name='bySunday'
							value='SU'
							checked={state.byweekday && state.byweekday.includes('SU')}
							onChange={toggleByDay}
						/>}
						label='Sunday'
					/>
				</FormGroup>
					</AccordionDetails>
				</Accordion>	
			</FormControl>
			<FormControl className={classes.accordion}  variant='outlined' component='fieldset'>
			<Accordion>
					<AccordionSummary  expandIcon={<ExpandMoreIcon />}>
						<FormLabel>Only During Months</FormLabel>
					</AccordionSummary>
					<AccordionDetails>
					<FormGroup row>
					<FormControlLabel
						control={<Checkbox
							name='month'
							value={1}
							checked={state.bymonth && state.bymonth.includes(1)}
							onChange={toggleByMonth}
						/>}
						label='January'
					/>
					<FormControlLabel
						control={<Checkbox
							name='month'
							value={2}
							checked={state.bymonth && state.bymonth.includes(2)}
							onChange={toggleByMonth}
						/>}
						label='February'
					/>
					<FormControlLabel
						control={<Checkbox
							name='month'
							value={3}
							checked={state.bymonth && state.bymonth.includes(3)}
							onChange={toggleByMonth}
						/>}
						label='March'
					/>
					<FormControlLabel
						control={<Checkbox
							name='month'
							value={4}
							checked={state.bymonth && state.bymonth.includes(4)}
							onChange={toggleByMonth}
						/>}
						label='April'
					/>
					<FormControlLabel
						control={<Checkbox
							name='month'
							value={5}
							checked={state.bymonth && state.bymonth.includes(5)}
							onChange={toggleByMonth}
						/>}
						label='May'
					/>
					<FormControlLabel
						control={<Checkbox
							name='month'
							value={6}
							checked={state.bymonth && state.bymonth.includes(6)}
							onChange={toggleByMonth}
						/>}
						label='June'
					/>
					<FormControlLabel
						control={<Checkbox
							name='month'
							value={7}
							checked={state.bymonth && state.bymonth.includes(7)}
							onChange={toggleByMonth}
						/>}
						label='July'
					/>
					<FormControlLabel
						control={<Checkbox
							name='month'
							value={8}
							checked={state.bymonth && state.bymonth.includes(8)}
							onChange={toggleByMonth}
						/>}
						label='August'
					/>
					<FormControlLabel
						control={<Checkbox
							name='month'
							value={9}
							checked={state.bymonth && state.bymonth.includes(9)}
							onChange={toggleByMonth}
						/>}
						label='September'
					/>
					<FormControlLabel
						control={<Checkbox
							name='month'
							value={10}
							checked={state.bymonth && state.bymonth.includes(10)}
							onChange={toggleByMonth}
						/>}
						label='October'
					/>
					<FormControlLabel
						control={<Checkbox
							name='month'
							value={11}
							checked={state.bymonth && state.bymonth.includes(11)}
							onChange={toggleByMonth}
						/>}
						label='November'
					/>
					<FormControlLabel
						control={<Checkbox
							name='month'
							value={12}
							checked={state.bymonth && state.bymonth.includes(12)}
							onChange={toggleByMonth}
						/>}
						label='December'
					/>
				</FormGroup>
				</AccordionDetails>
			</Accordion>
			</FormControl>
			<br></br>
			<Divider></Divider>
			<br></br>
			<InputLabel className={classes.formControl}  id="rununtil-label">Duration</InputLabel>
			<Select fullWidth className={classes.formControl}  onChange={handleRunMode} labelId="rununtil-label" id="rununtil" value={runmode}>
				<MenuItem value={0}>Until Stopped</MenuItem>
				<MenuItem value={1}>Until Date</MenuItem>
				<MenuItem value={2}>Count</MenuItem>
			</Select>
			{ runmode == 0 &&
				<Grid container justify="space-around">
					<Grid item>
					<div>Runs until completed/stopped/deleted</div>
					</Grid>
				</Grid>
				
			}
			{ runmode == 1 &&
				<MuiPickersUtilsProvider utils={MomentUtils}>
				<Grid container justify="space-around">
					<KeyboardDatePicker className={classes.formControl} 
						disableToolbar
						variant="inline"
						format="MM/DD/yyyy"
						margin="normal"
						id="date-picker-inline"
						label="Until Date"
						value={state.until}
						onChange={(d) => setState({ ...state, until: moment(d).format('YYYY-MM-DDThh:mm') })}
						KeyboardButtonProps={{
							'aria-label': 'change date',
						}}
					/>
					<KeyboardTimePicker className={classes.formControl} 
						margin="normal"
						id="time-picker"
						label="Time"
						value={state.until}
						onChange={(d) => setState({ ...state, until: moment(d).format('YYYY-MM-DDThh:mm') })}
						KeyboardButtonProps={{
							'aria-label': 'change time',
						}}
						/>
				</Grid>
				</MuiPickersUtilsProvider>
			}
			{ runmode==2 &&
				<FormControl className={classes.formControl} variant='outlined' fullWidth>
				<Input variant='outlined'
					type='number'
					name='count'
					max='1000'
					min='1'
					value={state.count}
					onChange={(e) => setState({ ...state, count: +e.target.value })}
				/>
				</FormControl>
			}
			
			<br></br>
			<Divider></Divider>
			<FormControl className={classes.formControl} fullWidth>
				<FormLabel>Week Starts On</FormLabel>
				<Select variant='outlined'
					value={state.wkst}
					onChange={(e) => setState({ ...state, wkst: +e.target.value })}
				>
					{generateWeekStart()}
				</Select>
			</FormControl>
			<Divider></Divider>

			<Button fullWidth variant='outlined' onClick={() => setOpen(false) } >Done</Button>
		</Wrapper>
		</Box>
		}
		</>
	)
}

export default RRuleEditor