import React from 'react';
import { CircularProgress, Grid} from '@material-ui/core';

const LoadingIndicator = ({loading}) => {
	return(
		<span>
			{ loading && 
			<>
				<CircularProgress></CircularProgress>
				<div>Loading...</div>
			</>
			}
		</span>
		);
}

export default LoadingIndicator;